import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select'
import Button from '@material-ui/core/Button';
import { isLength, isPostalCode} from 'validator';

const styles = theme => ({
    container: {
        display: 'block',
        flexWrap: 'wrap',
        arginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '50px',
        maxWidth: '420px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
        display: 'flex',
    },
    scope: {
        width: '100%',
        maxWidth: '340px',
        marginTop: '50px',
        paddingLeft: '5px',
    },
    margin20: {
        marginBottom: '20px',
    },
    flex: {
        display: 'flex',
    },
    zIndex0: {
        zIndex: 0,
    },
    button: {
        height: '37px',
        top: '15px',
    },
    marginLeft5: {
        marginLeft: '5px',
    }
});

const EditProject = (props) => {
    const history = useHistory();
    const {
        classes,
        project,
        scopes,
        customScopeFieldEnabled,
        // history,
        handleChange,
        handleScopeChange,
        handleWorkDayChange,
        handleAddCustomScope,
        onSaveClick,
    } = props;

    const scopeDefaultValue = project.scopes && project.scopes.length > 0 ? project.scopes.map((scope) => {
        return {
            value: scope,
            label: scope.name,
        }
    }) : [];

    
    const scopeOptions = scopes.filter(scope => {
                            return !(project.scopes.find(element => element.name === scope.name))
                        }).map(scope => ({
                            value: scope,
                            label: scope.name
                        }));
    
    const disabled = !project.title || scopeDefaultValue.length < 1 || !project.budgetMin || !project.budgetMax
                    || !project.startDate || !project.startTime || !project.endTime || !project.zipcode || !project.endBiddingDate || project.day.length < 1;

    const titleError = !isLength(project.title || '', {min:1, max: 64}) ? "Title is too long. The max length is 64": undefined;
    const zipcodeError =  !isPostalCode(project.zipcode, 'US') ? "Invalid zipcode": undefined;
    return (
        <Container maxWidth="lg" className={classes.container}>
            {/* <div className={clsx(classes.margin20)}>
                <TextField
                    required
                    id="project-title"
                    label="Project Title"
                    className={clsx(classes.wide, classes.margin)}
                    variant="outlined"
                    value={project.title}
                    onChange={(event) => handleChange('title', event)}
                    margin="normal"
                    error={titleError}
                    helperText={titleError ? titleError :  "Define your project title"}
            />
            </div> */}
            <div className={clsx(classes.margin20)}>
                 <FormControl 
                    className={clsx(classes.wide, classes.label)}>
                    <InputLabel htmlFor="project-scopes">What electrical or wiring service do you need?</InputLabel>
                    <Select
                        required
                        isMulti
                        name="project-scopes"
                        value={scopeDefaultValue}
                        options={scopeOptions}
                        className={classes.scope}
                        classNamePrefix="select"
                        onChange={(selection) => {
                            handleScopeChange(selection);
                        }}
                    />
                </FormControl>
            </div>
            {customScopeFieldEnabled && 
            <div className={clsx(classes.margin20, classes.flex)}>
                <TextField
                    required
                    id="custom-scope"
                    label="Custom Scope"
                    className={clsx(classes.wide, classes.margin, classes.zIndex0)}
                    variant="outlined"
                    onChange={(event) => handleChange('custom_scope', event)}
                    margin="normal"
                    error={titleError}
                    helperText={titleError ? titleError :  "Define your scope"}
            />
                <Button
                    disabled={project.customScope ? false : true}
                    variant={project.customScope ? 'contained' : 'outlined'} 
                    size="small" 
                    onClick={(event) => handleAddCustomScope()}
                    className={project.customScope ? clsx(classes.button, classes.margin, 'ee-bg-color', 'flex') : clsx(classes.button, classes.margin, 'flex')}
                >
                    Add
                </Button>
            </div>}

            <div className={clsx(classes.margin20, classes.flex)}>
                <TextField
                    required
                    id="budget-min"
                    className={clsx(classes.narrow, classes.margin, classes.zIndex0, classes.flex)}
                    variant="outlined"
                    label="Minimum Budget"
                    value={project.budgetMin}
                    onChange={(event) => handleChange('budgetMin', event)}
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
                <TextField
                    required
                    id="budget-max"
                    className={clsx(classes.narrow, classes.margin, classes.zIndex0, classes.flex)}
                    variant="outlined"
                    label="MaximumBudget"
                    value={project.budgetMax}
                    onChange={(event) => handleChange('budgetMax', event)}
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </div>
            <div className={clsx(classes.margin20, classes.flex)}>
                <TextField
                    required
                    id="start-date"
                    label="Start date"
                    variant="outlined"
                    type="date"
                    defaultValue={project.startDate }
                    onChange={(event) => handleChange('startDate', event)}
                    className={clsx(classes.margin, classes.zIndex0, classes.flex)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className={clsx(classes.margin20, classes.flex)}>
                <TextField
                    id="start-time"
                    label="Start time"
                    type="time"
                    defaultValue={project.startTime || "07:30"}
                    variant="outlined"
                    onChange={(event) => handleChange('startTime', event)}
                    className={clsx(classes.margin, classes.zIndex0, classes.flex)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 600, // 5 min
                    }}
                />
                <TextField
                    id="end-time"
                    label="End time"
                    type="time"
                    defaultValue={project.endTime || "17:30"}
                    variant="outlined"
                    onChange={(event) => handleChange('endTime', event)}
                    className={clsx(classes.margin, classes.zIndex0, classes.flex)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 600, // 5 min
                    }}
                />
            </div>
            <div className={clsx(classes.margin20, classes.flex)}>
                <TextField
                    required
                    id="zipcode"
                    label="Zipcode"
                    className={clsx(classes.margin, classes.zIndex0, classes.flex)}
                    variant="outlined"
                    value={project.zipcode}
                    onChange={(event) => handleChange('zipcode', event)}
                    margin="normal"
                    error={zipcodeError}
                    helperText={zipcodeError ? zipcodeError : ''}
                />
            </div>
            <div className={clsx(classes.margin20, classes.flex)}>
            <FormControlLabel
                control={
                <Checkbox
                    checked={project.emergency}
                    onChange={(event) => handleChange('emergency', event)}
                    value="emergency"
                    color="default"
                />
                }
                label="Emergency"
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={project.company}
                    onChange={(event) => handleChange('company', event)}
                    value="company"
                    color="default"
                />
                }
                label="Company"
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={project.insurance}
                    onChange={(event) => handleChange('insurance', event)}
                    value="insurance"
                    color="default"
                />
                }
                label="Insurance"
            />
            <FormControlLabel
                control={
                <Checkbox
                    checked={project.license}
                    onChange={(event) => handleChange('license', event)}
                    value="license"
                    color="default"
                />
                }
                label="License"
            />
        </div>
        {/* <div className={clsx(classes.margin20, classes.narrow)}>
            <TextField
                required
                id="bidding-date"
                label="Bidding date"
                variant="outlined"
                type="date"
                defaultValue={project.endBiddingDate}
                onChange={(event) => handleChange('endBiddingDate', event)}
                className={classes.margin}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </div> */}
        <div className={clsx(classes.margin20)}>
            <TextField
                id="supportingInfo"
                label="Additional info"
                variant="outlined"
                multiline
                maxRows="4"
                minRows="4"
                value={project.supportingInfo}
                onChange={(event) => handleChange('supportingInfo', event)}
                className={clsx(classes.wide, classes.margin)}
                margin="normal"
                helperText= "Write additional info..."
            />
        </div>
        <div className="project">
            <label className={classes.label}>Work date</label> 
            <div className="flex">
            <ButtonGroup
              size="large"
            >
                {project.day.map(day => (
                    <Button 
                        variant={day.value ? 'contained' : 'outlined'} 
                        size="small" 
                        onClick={(event) => handleWorkDayChange(day.day, day.value)}
                        // selected={day.value}
                        className={day.value ? clsx(classes.margin, 'ee-bg-color', 'flex') : clsx(classes.margin, 'flex')}
                    >
                        {day.day}
                    </Button>
                ))}
            </ButtonGroup>
            </div>
        </div>
        <div className={clsx(classes.wide, classes.margin20)}>
            <Button 
                disabled={disabled}
                variant="contained" 
                component="span" 
                onClick={() => onSaveClick(history)}
                className={disabled ? clsx(classes.wide, classes.marginLeft5, 'flex') : clsx(classes.wide, classes.marginLeft5, 'ee-bg-color', 'flex')}
            >
                Save
            </Button>
        </div>
    </Container>
    );
}

export default withStyles(styles)(EditProject);