import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditProposal from '../components/EditProposal';
import { updateScopeType, 
        selectProposalScopeClick, 
        updateProposalValue, 
        updateProposalScopeValue, 
        addProposalScope, 
        prepareEditScopeDetail,
        cancleProposalScope } from '../../actions/ee';
import { fetchGetProposal, fetchSaveProposal, fetchAllScopes } from '../../actions/api';

const mapStateToProps = (state, ownProps) => {
    const projectId = (ownProps.match.params.projectId !== undefined) ? ownProps.match.params.projectId : null;
    return {
        user: state.ee.user,
        projectId,
        project: state.ee.project,
        proposal: state.view.proposal,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getProposalDataByProjectId: (projectId) => {
        dispatch(fetchGetProposal(projectId));
    },
    getScopeData: (history) => {
        dispatch(fetchAllScopes(history));
    },
    handleChange: (name, event) => {
        dispatch(updateProposalValue(
            name, 
            event.target.value
        ));
    },
    handleScopeValueChange: (name, event) => {
        dispatch(updateProposalScopeValue(
            name, 
            event.target.value
        ));
    },
    handleScopeChange: (selectionList) => {
        const proposalScopes = selectionList.map((selection) => {
            return selection.value;
        })
        dispatch(updateProposalValue(
            'scopes',
            proposalScopes,
        ));
    },
    handleEditScopeClick: (scopeId) => {
        dispatch(prepareEditScopeDetail(scopeId));
    },
    handleAddProposal: (history) => {
        dispatch(fetchSaveProposal(history));
    },
    handleSelectScopeClick: (id, name) => {
        dispatch(selectProposalScopeClick(id, name))
    },
    handleScopeType: (scopeType) => {
        dispatch(updateScopeType(scopeType))        
    },
    handleAddProposalScope: (userRate) => {
        dispatch(addProposalScope(userRate));        
    },
    handleCancelProposalScope: () => {
        dispatch(cancleProposalScope());
    },
    handleCancelProposal: (history, projectId) => {
        history.push('/project/' + projectId);
    },
});

class EditProposalContainer extends Component {
    componentDidMount() {
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
        this.props.getProposalDataByProjectId(this.props.projectId);
    }

    componentDidUpdate(newProps) {
        if (!this.props.scopes || this.props.scopes.length === 0) {
            this.props.getScopeData(this.props.history);
        }
        if (newProps.projectId !== this.props.projectId) {
            this.props.getProposalDataByProjectId(newProps.projectId);
        }
    }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                <EditProposal
                    projectId={this.props.projectId}
                    proposal={this.props.proposal}
                    scopes={this.props.scopes}
                    user={this.props.user}
                    history={this.props.history} 
                    handleChange={this.props.handleChange}
                    handleScopeValueChange={this.props.handleScopeValueChange}
                    handleSelectScopeClick={this.props.handleSelectScopeClick}
                    handleScopeType={this.props.handleScopeType}
                    addProposalScope={this.props.addProposalScope}
                    editProposalScope={this.props.editProposalScope}
                    deleteProposalScope={this.props.deleteProposalScope}
                    cancelProposalScope={this.props.cancelProposalScope}
                    handleAddProposalScope={this.props.handleAddProposalScope}
                    handleCancelProposalScope={this.props.handleCancelProposalScope}                    
                    handleAddProposal={this.props.handleAddProposal}
                    handleCancelProposal={this.props.handleCancelProposal}
                    addProposal={this.props.addProposal}
                    handleEditScopeClick={this.props.handleEditScopeClick}
                />
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProposalContainer);
