import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux'
import moment from 'moment';
import * as types from '../constants/actionTypes';
import { updateNotification, 
    closeMessaegDialog, 
    enableMessageSubmit,
    hideSpinner,
    showSpinner,
    initContact,
} from '../actions/view';
import { updateUserType } from '../actions/ee';
import { updateAccountActivation, updateNotificationSetting } from './setting'
import { updateStep, updateUserCompany, updateUserLicense, receivedProfile } from '../actions/register';
import { queryToArray } from '../util/urlUtil';

const cookies = new Cookies();
 
const getReq = () => ({ credentials: 'same-origin' });

const getBuffer = () => ({
    method: 'GET',
    headers: {
        'Accept': 'application/pdf',
    },
    responseType: 'arraybuffer'
})

const putReq = body => ({
    method: 'PUT',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'same-origin',
});

const postReq = body => ({
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    credentials: 'same-origin',
});

const postReqFormData = file => {
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('image', file.image);
    formData.append('name', file.file.name);
    return {
        method: 'POST',
        body: formData,
        credentials: 'same-origin',    
    }
}

const postNonJsonReq = body => ({
    method: 'POST',
    headers: {
        Accept: 'application/json, */*',
    },
    body,
    credentials: 'same-origin',
});

export const evaluateResponseBufferWithErrorMessage = (response, isJson = false, isRedirect = false) => {
    if (response.ok) {
        return response.arrayBuffer();
    }

    return response.json().then((json) => {
        jsonErrorHandler(json, isRedirect);
    });
};

export const evaluateResponseNoJsonWithErrorMessage = (response, isJson = false, history) => {
    if (response.ok) {
        return response;
    }

    if (response.statusText === 'USER NOT FOUND') {
        if (history) {
            history.push('/login');
        }
    } else {
        throw new Error(response.statusText);
    }
};

export const evaluateResponseWithErrorMessage = (response, isJson = true, history, dispatch) => {
    if (response.ok) {
        return (isJson) ? response.json() : response;
    }

    return response.json().then((json) => {
        jsonErrorHandler(json, history, dispatch);
    });
};

const jsonErrorHandler = (json, history = undefined, dispatch = undefined, toHome = false) => {
    let errorLines;
    if (json.message === 'USER NOT FOUND' || json.error === 'USER NOT FOUND') {
        if (history) {
            history.push('/login');
        }
        dispatch(updateNotification({
            display: true, 
            text: 'Login required',
            type: toast.TYPE.WARN
        }));    
    
        errorLines = [json.message];
        throw new Error(errorLines.join('\n'));
    } else {
        if (json.error_message) {
            errorLines = [json.error_message];
        } else if (json.errors) {
            errorLines = [json.errors];
        } else if (json.validationResult && json.validationResult.errors) {
            errorLines = json.validationResult.errors;
        } else if (json.error) {
            errorLines = [json.error];
        }
            
        if(history & toHome) {
            history.push('/');
        }
        throw new Error(errorLines.join('\n'));
    }
}

// export const evaluateUser = user => (
//     (dispatch) => {
//         if(user.userType === 's') {
//             logout();
//         }
//     }
// );


// export const evaluateResponse = (response) => {
//     if (response.status >= 400) {
//         console.error('Error Response', response);
//         throw new Error('There was an error with the request');
//     }
//     return response.json();
// };

export const receivedLogoutRequest = ({
    type: types.LOGOUT,
});

export const receiveUser = user => ({
    type: types.LOAD_USER, user,
});

export const receiveDashboard = dashboard => ({
    type: types.RECEIVED_DASHBOARD, dashboard,
});

export const receivedPublicDashboard = dashboard => ({
    type: types.RECEIVED_PUBLIC_DASHBOARD, dashboard,
})

export const receivedProfileImage = profileImage => ({
    type: types.RECEIVED_PROFILE_IMAGE, profileImage,
})

export const receiveScopes = scopes => ({
    type: types.RECEIVED_SCOPES, scopes,
})

export const receiveProject = projectResponse => ({
    type: types.RECEIVED_PROJECT, projectResponse,
});

export const selectSaved = projectResponse => ({
    type: types.SELECT_SAVED, projectResponse,
})

export const receiveProjectProject = projectResponse => ({
    type: types.RECEIVED_PROJECT_PROJECT, projectResponse
})

export const receiveProposal = proposalResponse => ({
    type: types.RECEIVED_PROPOSAL, proposalResponse,
});

export const receiveQuickOffer = response => ({
    type: types.RECEIVED_QUICKOFFER, response,
});

export const receivedWorkZone = workZoneResponse => ({
    type: types.RECEIVED_WORKZONE, 
    workzone: workZoneResponse,
});

export const receivedWorkHour = workHourResponse => ({
    type: types.RECEIVED_WORKHOUR, 
    workHour: workHourResponse,
});

export const receivedPhone = phone => ({
    type: types.RECEIVED_PHONE,
    phone: phone,
})

export const receivedHourlyRate = userHourlyRateResponse => ({
    type: types.RECEIVED_HOURLY_RATE,
    rate: userHourlyRateResponse,
});

export const loadUser = () => ({
    type: types.LOAD_USER,
    user: {
        type: 's',
        email: '',
    },
});

export const initUpdatePassword = () => ({
    type: types.INIT_UPDATE_PASSWORD, 
})

export const ableLoginLink = () => ({
    type: types.ENABLE_LOGIN_LINK,
});

export const fetchUser = (userId) => (
    (dispatch) => {
        const loginCheckURL = '/v1/home/check';
        return fetch(loginCheckURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    const user = response;
                    dispatch(receiveUser(user));
                } else {
                    console.error('user not found');
                }
            })
            .catch((err) => {
                console.error('Error Fetching user data', err);
            });
    }
);

export const fetchDashboard = history => (
    (dispatch) => {
        const dashboardURL = '/v1/dashboard';
        return fetch(dashboardURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    const dashboard = response;
                    dispatch(receiveDashboard(dashboard));
                } else {
                    console.error('dashboard not found');
                    history.push('/');
                }
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching dashboard',
                    type: toast.TYPE.ERROR
                }));
                history.push('/');
            });
    }
);

export const fetchPublicDashboard = providerId => (
    (dispatch) => {
        let dashboardURL = '/v1/dashboard/public';
        if(providerId) {
	        dashboardURL = dashboardURL + '?pid=' + providerId;
	    }
        return fetch(dashboardURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    const dashboard = response;
                    dispatch(receivedPublicDashboard(dashboard));
                } else {
                    console.error('public dashboard not found');
                }
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching public dashboard',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

const arrayBufferToBase64 = function(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa( binary );
};

export const fetchProfileImage = providerId => (
    (dispatch) => {
        let url = '/v1/dashboard/profileImage';
        if(providerId) {
            url = url + '?pid=' + providerId;
	    }
        return fetch(url, getReq())
            .then(evaluateResponseBufferWithErrorMessage)
            .then((response) => {
                const profile_image = response;
                if(profile_image) {
                    dispatch(receivedProfileImage('data:image/png;base64,'+arrayBufferToBase64(profile_image)));
                } else {
                    console.error('profile image not found');
                }
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching profile image',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveProfileImage = history => (
    (dispatch, getState) => {
        let profileImage = { ...getState().register.profileImage };
        const url = '/v1/profile/update/profileImage';    
        
        dispatch(showSpinner());
        return fetch(url, postReqFormData(profileImage))
            .then(evaluateResponseBufferWithErrorMessage)
            .then((response) => {
                const profile_image = response;
                if(profile_image) {
                    dispatch(hideSpinner());
                    dispatch(receivedProfileImage('data:image/png;base64,'+arrayBufferToBase64(profile_image)));
                } else {
                    console.error('profile image not found');
                }
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching profile image',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchAllScopes = history => (
    (dispatch) => {
        const scopeUrl = '/v1/scope';
        return fetch(scopeUrl, getReq())
            .then((response) => evaluateResponseWithErrorMessage(response, true, history, dispatch))
            .then((response) => {
                if (response) {
                    let scopes = [];
                    response.scopes.map(scope => (
                        scopes[scope.id] = scope
                    ));
                    dispatch(receiveScopes(scopes));
                } else {
                    console.error('error Fetching data');
                }
            })
            .catch((err) => {
                console.error('error Fetching data', err);
            });
    }
);

export const fetchGetProject = projectId => (
    (dispatch) => {
        const projectURL = '/v1/project?id=' + projectId;
        return fetch(projectURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    dispatch(receiveProject(response));
                } else {
                    console.error('project not found');
                }
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching project',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

const buildScopeURL = function(projectScopes, isSelect = false) {
    let url ='';
    for(let i = 0; i < projectScopes.length; i++){
        const projectScope = projectScopes[i];
        if(!isSelect || (isSelect && projectScope.providerId && projectScope.providerDisplayName)) {
            const jsonString = JSON.stringify(projectScope);
            url += '&scope=' + jsonString;    
        }
    }
    return url;
}

const buildProjectUrl = function(project) {
    var url = '';
    url += 'title=' + project.title;
    url += '&budgetMax=' + project.budgetMax;
    url += '&budgetMin=' + project.budgetMin;
    url += '&date=' + Date.now();
    url += '&endBiddingDate=' + new Date(project.endBiddingDate).getTime();
    url += '&expectStartDate=' + new Date(project.startDate).getTime();
    url += '&startTime=' + project.startTime;
    url += '&endTime=' + project.endTime;
    url += '&sun=' + project.sun;
    url += '&mon=' + project.mon;
    url += '&tue=' + project.tue;
    url += '&wed=' + project.wed;
    url += '&thu=' + project.thu;
    url += '&fri=' + project.fri;
    url += '&sat=' + project.sat;
    url += '&emergency=' + project.emergency;
    url += '&company=' + project.company;
    url += '&license=' + project.license;
    url += '&insurance=' + project.insurance;
    url += '&supportingInfo=' + project.supportingInfo;
    url += '&zipcode=' + project.zipcode;
    if(project.id) {
        url += '&id=' + project.id;
    }
    url += buildScopeURL(project.scopes);

    return url;
}

export const fetchSaveProject = history => (
    (dispatch, getState) => {
        let project = { ...getState().ee.project };
        //TODO: use post with data(not using params)

        // validateTitle();
		// validateWorkDay();
		// validateScope();
		// var url = buildProjectUrl(project);
        // const saveProjectURL = '/v1/project/save?' + url;    
        const saveProjectURL = '/v1/project/save';    
        delete project.blocked;
        delete project.owner;
        delete project.offers;
        delete project.proposals;
        delete project.providerList;
        delete project.reviews;
        delete project.customScope;
        delete project.createdDate;
        delete project.updatedDate;
        delete project.day;
        delete project.proposalCount;
        delete project.scopeCount;

        dispatch(showSpinner());
        return fetch(saveProjectURL, postReq(project))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                const projectTitle = response.title;
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: `Project "${projectTitle}" is saved.`,
                    type: toast.TYPE.SUCCESS,
                }));
                history.push(`/project/${response.projectId}`);
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }
);

export const fetchRemoveProject = (projectId, history) => (
    (dispatch, getState) => {
        let user = { ...getState().ee.user };
        let removeProjectURL;
        if(user.userType === 'c' || user.userType === 'a') {
            removeProjectURL = '/v1/project/archive?id=' + projectId;
        } else {
            removeProjectURL = '/v1/project/delete?id=' + projectId;    
        }

        dispatch(showSpinner());
        return fetch(removeProjectURL, postReq({}))
            .then(evaluateResponseNoJsonWithErrorMessage)
            .then((json) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: `Project ${projectId} is removed.`,
                    type: toast.TYPE.SUCCESS,
                }));
                history.push('/dashboard');
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }
);

export const fetchCloseProject = (projectId, history) => (
    (dispatch, getState) => {
        let user = { ...getState().ee.user };
        if(user.userType === 'c' || user.userType === 'a') {
            const projectURL = '/v1/project/close?id=' + projectId;
            dispatch(showSpinner());
            return fetch(projectURL, postReq({}))
                .then(evaluateResponseNoJsonWithErrorMessage)
                .then((response) => {
                    if (response) {
                        const project = response;
                        dispatch(receiveProject(project));
                        dispatch(hideSpinner());
                        dispatch(updateNotification({
                            display: true, 
                            text: `Project ${projectId} is closed.`,
                            type: toast.TYPE.SUCCESS,
                        }));
                        history.push(`/project/${response.project.id}`);
                    } else {
                        console.error('project not found');
                    }
                })
                .catch((err) => {
                    dispatch(hideSpinner());
                    dispatch(updateNotification({
                        display: true, 
                        text: 'Error Fetching project',
                        type: toast.TYPE.ERROR
                    }));
                });

        } else {
            dispatch(hideSpinner());
            dispatch(updateNotification({
                display: true, 
                text: `Erro Project ${projectId} removing.`,
                type: toast.TYPE.ERROR,
            }));
        }
    }    
);

export const fetchRemoveProposal = (proposalId) => (
    (dispatch, getState) => {
        let removeProposalURL = '/v1/proposal/delete?id=' + proposalId;
        dispatch(showSpinner());
        return fetch(removeProposalURL, postReq({}))
            .then(evaluateResponseNoJsonWithErrorMessage)
            .then((json) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: `Proposal ${proposalId} is removed.`,
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                console.error(err);
            });
    }
);

export const fetchSelect = (proposalId, projectId, providerId, scopes) => (
    (dispatch, getState) => {
        let user = { ...getState().ee.user };
        const scopeIds = scopes.map(scope => (scope.scopeId))
        let removeProposalURL = '/v1/project/select/' + proposalId;
        const data = {
            scopes: scopeIds,
            projectId: projectId,
            providerId: providerId
        };
        dispatch(showSpinner());
        return fetch(removeProposalURL, postReq(data))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(hideSpinner());
                dispatch(selectSaved(response));
                dispatch(updateNotification({
                    display: true, 
                    text: `${proposalId} is selected.`,
                    type: toast.TYPE.SUCCESS,
                }));
                // history.push(`/project/${response.project.id}`);
            })
            .catch((err) => {
                console.error(err);
            });
    }
);

export const SIMPLE_DAY_DATE_FORMAT = 'YYYYMMDD';
export const SIMPLE_DAY_DATE_FORMAT_REGISTER = 'YYYY-MM-DD';

var buildSaveReviewUrl = (review) => {
    var url = '';
    url += 'projectId=' + review.projectId;
    url += '&providerId=' + review.providerId;
    url += '&status=' + review.status;
    url += '&recommend=' + review.recommend;
    url += '&responsiveness=' + review.responsiveness;
    url += '&quality=' + review.quality;
    url += '&professionalism=' + review.professionalism;
    url += '&subjectMatterExpertise=' + review.subjectMatterExpertise;
    url += '&adherenceToCost=' + review.adherenceToCost;
    url += '&adherenceToSchedule=' + review.adherenceToSchedule;
    url += '&comments=' + review.comment;

    return url;
}

export const fetchGetProposal = projectId => (
    (dispatch) => {
        const proposalURL = '/v1/proposal/get?pid=' + projectId;
        return fetch(proposalURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    dispatch(receiveProposal(response));
                } else {
                    console.error('proposal not found');
                }
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching proposal',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchGetQuickOffer = projectId => (
    (dispatch) => {
        const proposalURL = '/v1/proposal/quickoffer?pid=' + projectId;
        return fetch(proposalURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    dispatch(receiveQuickOffer(response.offer));
                } else {
                    console.error('quickOffer not found');
                }
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching quickOffer',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchAcceptOffer = quickOfferlId => (
    (dispatch, getState) => {
        const user = { ...getState().ee.user };
        // const quickOffer = { ...getState().ee.quickOffer };
        const acceptOfferlURL = '/v1/proposal/quickoffer/accept?id=' + quickOfferlId;
        const requestData = { 
            userId: user.id,
            quickOfferId: quickOfferlId,
        };
        dispatch(showSpinner());
        return fetch(acceptOfferlURL, postReq({}))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: `You accepted the Offer ID ${quickOfferlId}`,
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching quickOffer',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);


const getProposalScope = selectedScopes => {
    const proposalScopes = selectedScopes.map((selected) => {
        return {
            proposalId: selected.proposalId,
            scopeId: selected.scopeId,
            type: selected.type,
            key: selected.key, 
            description: selected.description,
            costTotal: selected.scopeTotalCost,
            hour: selected.hour,
            unit: selected.unit,
            material: selected.material,
            comment: selected.comment,
        }
    })
    return proposalScopes;
}

export const fetchSaveProposal = history => (
    (dispatch, getState) => {
        const proposal = { ...getState().view.proposal };
        const project = { ...getState().view.project };
        let proposalId;
        if (proposal.id) {
            proposalId = proposal.id;
        }
        if(proposal.proposalId) {
            proposalId = proposal.proposalId;
        }
    
        const expectStartDate = moment(proposal.startDate).format(SIMPLE_DAY_DATE_FORMAT)
        let requestData = {
            projectId: project.id,
            expectStartDate: expectStartDate,
            expectTime: proposal.expectTime,
            comment: proposal.comment,
            scopes: getProposalScope(proposal.selectedScopes),
        }
        if (proposalId) {
            requestData = {
                ...requestData,
                id: proposalId,
            }
        }
        
        const saveProposalURL = '/v1/proposal/save';
        dispatch(showSpinner());
        return fetch(saveProposalURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: `Proposal is saved.`,
                    type: toast.TYPE.SUCCESS,
                }));
                dispatch(receiveProposal(response));
                // history.push('/editProposal/${response.project.id}');
                // history.push(`/project/${response.project.id}`);
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching proposal',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveQuickOffer = (history, projectId) => (
    (dispatch, getState) => {
        let quickOffer = { 
            ...getState().ee.quickOffer,
            projectId: projectId,
            providerId: getState().ee.user.id,
            providerDisplayName: getState().ee.user.userDisplayName,
            company: getState().ee.user.company
        };
        delete quickOffer.update;
        // var requestData = {
        //     'name' : newUser.company.name,
        //     'registeredAt' : newUser.company.registeredAt,
        //     'companyId' : newUser.company.companyId
        // };

        const saveQuickOfferyURL = '/v1/proposal/quickoffer';
        dispatch(showSpinner());
        return fetch(saveQuickOfferyURL, postReq(quickOffer))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                extendUserCookieExpiry();
                dispatch(updateUserCompany(response.quickOffer))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'QuickOffer Saved.',
                    type: toast.TYPE.SUCCESS,
                }));
                history.push('/dashboard'); 
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching QuickOffer',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveSelectScopes = projectId => (
    (dispatch, getState) => {
        let project = { ...getState().ee.project };
        var url = buildScopeURL(project.scopes, true);
        const selectScopesURL = `/v1/project/select?projectId=${project.id}${url}`;
        dispatch(showSpinner());
        return fetch(selectScopesURL, postReq({}))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(updateNotification({
                    display: true,
                    text: `Scope is selected.`,
                    type: toast.TYPE.SUCCESS,
                }));
                // dispatch(receiveProjectProject(response));
                dispatch(receiveProject(response));
                dispatch(receiveProposal(response.proposals));
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching selection',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveReview = (history) => (
    (dispatch, getState) => {
        let review = { ...getState().ee.review };
        var url = buildSaveReviewUrl(review);
        const saveReviewURL = '/v1/review?';
        delete review.status;
        dispatch(showSpinner());
        return fetch(saveReviewURL, postReq(review))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: `Review is saved.`,
                    type: toast.TYPE.SUCCESS,
                }));
                // dispatch(receiveProject(response));
                history.push(`/project/${review.projectId}`);
                //redirect to project
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching review',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveRegisterNewUser = history => (
    (dispatch, getState) => {
        let newUser = { ...getState().register};
        const requestData = {
            'firstName' : newUser.firstName,
            'lastName' : newUser.lastName,
            'displayName' : newUser.displayName,
            'email' : newUser.email,
            'password' : newUser.password,
            'verifyPassword' : newUser.verifyPassword,
            'type' : newUser.type
        };
        const saveRegisterURL = '/v1/register';
        dispatch(showSpinner());
        return fetch(saveRegisterURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                extendCookieExpiry(response.u);
                dispatch(receiveUser(response));
                dispatch(hideSpinner());
                if(response.userType === 'c') {
                    dispatch(updateNotification({
                        display: true,
                        text: `Welcome, ${response.userDisplayName}! Start with the new project`,
                        type: toast.TYPE.SUCCESS,
                    }));
                    history.push('/editProject/new'); 
                } else if(response.userType === 'p1') {
                    dispatch(updateStep(1));
                    dispatch(updateNotification({
                        display: true,
                        text: `Welcome, ${response.userDisplayName}!`,
                        type: toast.TYPE.SUCCESS,
                    }));
                }
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: err.message,
                    type: toast.TYPE.ERROR
                }));
            });
    }
)
 
const savePassword = (url, requestData, dispatch) => {
    return fetch(url, postReq(requestData))
    .then(evaluateResponseNoJsonWithErrorMessage)
    .then((response) => {
        dispatch(hideSpinner());
        dispatch(updateNotification({
            display: true,
            text: 'Saved.',
            type: toast.TYPE.SUCCESS,
        }));
        dispatch(initUpdatePassword());
    })
    .catch((err) => {
        dispatch(hideSpinner());
        dispatch(updateNotification({
            display: true, 
            text: 'Error Fetching password updated',
            type: toast.TYPE.ERROR
        }));
    });   

}

export const fetchUpdatePassword = password => (
    (dispatch, getState) => {
        let password = { ...getState().setting.password};
        var requestData = {
            'currentPassword': password.currentPassword,
            'newPassword': password.newPassword,
            'verifyNewPassword': password.verifyNewPassword
        };
        
        const updatePasswordURL = '/v1/account/pw';
        return savePassword(updatePasswordURL, requestData, dispatch);
    }
);

export const fetchVerify = (token, queryStr) => (dispatch, getState) => {
    const queryParams = queryToArray(queryStr)
    const requestData = {
        'email': queryParams.email,
        'code': queryParams.c,
        'url': window.location.origin,
    }
    const url = '/v1/password/rs/' + token
    return fetch(url, postReq(requestData))
        .then(evaluateResponseWithErrorMessage)
        .then((response) => {
            window.location = `/resetPassword/${response.token}?email=${queryParams.email}`;
        })
        .catch((err) => {
            dispatch(updateNotification({
                display: true, 
                text: 'Error Fetching reset password',
                type: toast.TYPE.ERROR
            }));
        });   
};

export const fetchResetPassword = password => (
    (dispatch, getState) => {
        const password = { ...getState().setting.password};
        const requestData = {
            'email': password.email,
            'newPassword': password.newPassword,
            'verifyNewPassword': password.verifyNewPassword
        }
        const url = `/v1/password/update/${password.token}/`;
        return savePassword(url, requestData, dispatch);
    }
);

export const fetchSaveRegisterProviderTypeAndAgreement = register => (
    (dispatch, getState) => {
        let newUser = { ...getState().register};
        var requestData = {
            'type' : newUser.providerType,
            'termsOfUse' : newUser.agreement.termsOfUse,
            'termsOfUseProvider' : newUser.agreement.termsOfUseProvider,
            'codeOfEthics' : newUser.agreement.codeOfEthics
        };
        
        const saveRegisterURL = '/v1/register/provider/typeAgreement';
        dispatch(showSpinner());
        return fetch(saveRegisterURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                extendUserCookieExpiry();
                dispatch(updateUserType(response.userType));                
                dispatch(updateStep(2));
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching register',
                    type: toast.TYPE.ERROR
                }));
            });   
    }
);

export const fetchSaveRegisterProfile = history => (
    (dispatch, getState) => {
        let register = { ...getState().register};
        //phone, address, company, workzone
        const license = {
            ...register.license,
            issuedAt: moment(register.license.issuedAt).format(SIMPLE_DAY_DATE_FORMAT_REGISTER),
        }
        const requestData = {
            'address' : register.address.address,
            'city' : register.address.city,
            'stateProvince' : register.address.stateProvince,
            'zipcode' : register.address.zipcode,
            'phone' : register.phone,
            'companyId' : register.company.companyId,
            'companyName' : register.company.name,
            'companyRegisteredAt' :  moment(register.company.registeredAt).format(SIMPLE_DAY_DATE_FORMAT_REGISTER),
            'license' : license,
            'website' : register.website,
            'rate': register.rate,
        };

        const saveRegisterURL = '/v1/register/provider/detail';
        dispatch(showSpinner());
        return fetch(saveRegisterURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                extendUserCookieExpiry();
                dispatch(updateUserType(response.userType));                
                dispatch(updateStep(3));
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved.',
                    type: toast.TYPE.SUCCESS,
                }));
                history.push('/dashboard');
            })

            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching register',
                    type: toast.TYPE.ERROR
                }));
            });   
    }
);

export const fetchSaveCompany = history => (
    (dispatch, getState) => {
        let newUser = { ...getState().register};
        var requestData = {
            'name' : newUser.company.name,
            // Date-only strings (e.g. "1970-01-01") are treated as UTC, while date-time strings (e.g. "1970-01-01T12:00") are treated as local.
            'registeredAt' : new Date(newUser.company.registeredAt + "T12:00"),
            'companyId' : newUser.company.companyId
        };

        const saveCompanyURL = '/v1/profile/update/company';
        dispatch(showSpinner());
        return fetch(saveCompanyURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                extendUserCookieExpiry();
                dispatch(updateUserCompany(response))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Company Saved.',
                    type: toast.TYPE.SUCCESS,
                }));
                history.push('/dashboard'); //TODO: add insurance page and move there
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching register',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveLicense = company => (
    (dispatch, getState) => {
        let register = { ...getState().register};
        const requestData = {
            ...register.license,
            // Date-only strings (e.g. "1970-01-01") are treated as UTC, while date-time strings (e.g. "1970-01-01T12:00") are treated as local.
            issuedAt: new Date(register.license.issuedAt + "T12:00"),
        };
        delete requestData.userId;  // to avoid UnrecognizedPropertyException: Unrecognized field "userId" (class com.eelectricians.request.LicenseRequest)
        const saveLicenseURL = '/v1/profile/update/license';
        dispatch(showSpinner());
        return fetch(saveLicenseURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                // POST /v1/update/license returns a **list** of the updated license(s) of the user if succeeded,
                // as of Jan 26th 2023, we assume a user has only one license.
                // no change made for the API but here we try to get only the first element of the array
                const updatedLicense = !Array.isArray(response.license) ? response.license : response.license[0];

                extendUserCookieExpiry();
                dispatch(updateUserLicense(updatedLicense))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'License Saved.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching register',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchProfile = history => (
    (dispatch) => {
        const profileURL = '/v1/register/profile';
        return fetch(profileURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                extendUserCookieExpiry();
                const profile = response;
                dispatch(hideSpinner());
                dispatch(receivedProfile(profile))
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching profile',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchUpdateAddress = history => (    
    (dispatch, getState) => {
        let address = { ...getState().register.address};
        delete address.userId;
        delete address.date;
        const updateAddressURL = '/v1/profile/update/address';
        dispatch(showSpinner());
        return fetch(updateAddressURL, postReq(address))
            .then(evaluateResponseNoJsonWithErrorMessage)
            .then(() => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved Address',
                    type: toast.TYPE.SUCCESS,
                }));    
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error saving address',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchUpdatePhone = history => (    
    (dispatch, getState) => {
        const register = { ...getState().register};
        const updatePhoneURL = `/v1/profile/update/phone?phone=${register.phone}`;
        dispatch(showSpinner());
        return fetch(updatePhoneURL, postReq({}))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(hideSpinner());
                dispatch(receivedPhone(response.phone));
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved phone',
                    type: toast.TYPE.SUCCESS,
                }));    
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error saving phone',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchGetActivationStatus = company => (
    (dispatch) => {
        const profileURL = '/v1/account/activation/status';
        return fetch(profileURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                const activation = response;
                dispatch(hideSpinner());
                dispatch(updateAccountActivation(activation))
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching get account activation status',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);


export const fetchGetNotificationSettingStatus = () => (
    (dispatch) => {
        const profileURL = '/v1/account/notification/get';
        return fetch(profileURL, getReq())
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                const notification = response;
                dispatch(hideSpinner());
                dispatch(updateNotificationSetting(notification))
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching get account activation status',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchSaveNotificationSetting = () => (
    (dispatch, getState) => {
        let setting = { ...getState().setting};
        const notificationSetting = setting.notificationSetting;
        const saveAccountActivationURL = '/v1/account/notification'
        dispatch(showSpinner());
        return fetch(saveAccountActivationURL, postReq(notificationSetting))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                const notification = response;
                dispatch(hideSpinner());
                dispatch(updateNotificationSetting(notification))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved.',
                    type: toast.TYPE.WARNING,
                })); 
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching register',
                    type: toast.TYPE.ERROR
                }));
            });
    }
)

export const fetchSaveAccountActivation = type => (
    (dispatch, getState) => {
        let setting = { ...getState().setting};
        const type = setting.accountActivation.action || type;
        const saveAccountActivationURL = '/v1/account/activation?type=' + type
        dispatch(showSpinner());
        return fetch(saveAccountActivationURL, postReq({}))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(updateAccountActivation(response))
                if(response.message) {
                    dispatch(hideSpinner());
                    dispatch(updateNotification({
                        display: true,
                        text: response.message,
                        type: toast.TYPE.WARNING,
                    }));    
                } else {
                    dispatch(hideSpinner());
                    dispatch(updateNotification({
                        display: true,
                        text: 'Account Activation Updated. (Cannot change your status in 24 hours)',
                        type: toast.TYPE.SUCCESS,
                    }));    
                }
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'Error Fetching register',
                    type: toast.TYPE.ERROR
                }));
            });
    }
);

export const fetchContactSendRequest = history => (
    (dispatch, getState) => {
        const contact = { ...getState().view.contact};
        const requestContactUs = '/v1/help/contact'
        dispatch(showSpinner());
        return fetch(requestContactUs, postReq(contact))
            .then((response) => evaluateResponseWithErrorMessage(response, false, history, dispatch))
            .then((response) => {
                // dispatch(updateAccountActivation(response))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Successfully requested! We will reach out you soon!',
                    type: toast.TYPE.SUCCESS,
                }));
                dispatch(initContact());
            })
            .catch((err) => {
                dispatch(hideSpinner());
                if(!err.includes("UER NOT FOUND")) {
                    dispatch(updateNotification({
                        display: true, 
                        text: 'Error fetching',
                        type: toast.TYPE.ERROR
                    }));    
                }
            });
    }
);

//TODO: extend Cookie Expiry in response evaluator....
const extendCookieExpiry = function(cookieValue) {
    const now = new Date();
    const expiresValue = new Date(now);
    expiresValue.setMinutes(now.getMinutes() + 60);
    cookies.set('u', cookieValue, { path: '/' });
}

const extendUserCookieExpiry = function() {
    const now = new Date();
    const expiresValue = new Date(now);
    expiresValue.setMinutes(now.getMinutes() + 60);
    const uValue = cookies.get('u');
    cookies.set('u', uValue, { path: '/' });
}

const processLogin = function(user) {
    if(user.userType !== 's') {
        extendCookieExpiry(user.u);
        // checkProfileProgress(data.step);
        // $scope.verified = data.verified || false;
    }
};

export const login = history => (
    (dispatch, getState) => {
        let login = { ...getState().view.login };
        // login.url = 'localhost:8080/home';
        const loginURL = '/v1/home';
        dispatch(showSpinner());
        return fetch(loginURL, postReq(login))
            .then(evaluateResponseWithErrorMessage)
            .then((json) => {
                if (json) {
                    const user = json;
                    processLogin(user);
                    dispatch(receiveUser(user));
                    dispatch(hideSpinner());
                    dispatch(updateNotification({
                        display: true, 
                        text: 'Logged in successfully.',
                        type: toast.TYPE.SUCCESS
                    }));
                    push('/dashboard');
                } else {
                    dispatch(hideSpinner());
                    dispatch(updateNotification({
                        display: true, 
                        text: 'We cannot find your information',
                        type: toast.TYPE.ERROR,
                    }));

                    throw new Error('User not found');
                }
            })
            .catch((err) => {
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true, 
                    text: 'We cannot find your information',
                    type: toast.TYPE.ERROR,
                }));
                console.error(err);
            });
    }
);

export const fetchResetPasswordRequest = history => (
    (dispatch, getState) => {
        let view = { ...getState().view };
        let resetEmail= view.resetEmail;
		const requestData = {
            'email' : resetEmail,
            'url': window.location.href,
        };
        const url = '/v1/password/rs';
        return fetch(url, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((json) => {
                if (json) {
                    dispatch(updateNotification({
                        display: true, 
                        text: `Sent a reset password information to ${resetEmail}`,
                        type: toast.TYPE.SUCCESS
                    }));
                    dispatch(ableLoginLink());
                } else {
                    dispatch(updateNotification({
                        display: true, 
                        text: 'Something is wrong. Try again',
                        type: toast.TYPE.ERROR,
                    }));
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
);

export const logout = history => (
    (dispatch) => {
        dispatch(receivedLogoutRequest);
        cookies.remove('u', { path: '/' });
        history.push("/");
        window.location.reload();
    }
);

const buildSendMessageUrl = function (message) {
    var url = '';
    url += 'toId=' + message.replyId;
    url += '&text=' + message.text;
    if (message.projectId && message.projectId != undefined) {
        url += '&projectId=' + message.projectId;
    }
    if (message.userActionId && message.userActionId != undefined) {
        url += '&uid=' + message.userActionId;
    }
    return url;
}

export const fetchSendMessage = (message, history) => (
    (dispatch, getState) => {
        let message = { ...getState().view.message };
        if(message.replyDisplay && message.replyId) {
            const sendMessageUrl = '/v1/um?' + buildSendMessageUrl(message);
            return fetch(sendMessageUrl, postReq({}))
                .then(evaluateResponseWithErrorMessage)
                .then((json) => {
                    dispatch(enableMessageSubmit())
                    dispatch(closeMessaegDialog());
                    dispatch(updateNotification({
                        display: true,
                        text: `message is sent to ${message.replyDisplay});`,
                        type: toast.TYPE.SUCCESS,
                    }));
                    push('/dashboard');
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            dispatch(updateNotification({
                display: true,
                text: 'Please specify a recipient',
                type: toast.TYPE.ERROR,
            }));
        }
    }
);

export const fetchDeleteMessage = (messageId, history) => (
    (dispatch, getState) => {
        const sendMessageUrl = `/v1/um/delete?id=${messageId}`;
        return fetch(sendMessageUrl, postReq({}))
            .then(evaluateResponseNoJsonWithErrorMessage)
            .then(() => {
                dispatch(closeMessaegDialog());
                dispatch(updateNotification({
                    display: true,
                    text: 'removed message successfully.',
                    type: toast.TYPE.SUCCESS,
                }));
                push('/dashboard');
            })
            .catch((err) => {
                console.error(err);
            });
    }
);

export const fetchDeleteUserAction = (userActionId, history) => (
    (dispatch, getState) => {
        const sendMessageUrl = `/v1/useraction/delete?id=${userActionId}`;
        dispatch(showSpinner());
        return fetch(sendMessageUrl, postReq({}))
            .then(evaluateResponseNoJsonWithErrorMessage)
            .then(() => {
                dispatch(closeMessaegDialog());
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'removed notification successfully.',
                    type: toast.TYPE.SUCCESS,
                }));
                push('/dashboard');
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }    
);

export const fetchSaveWorkZone = history => (
    (dispatch, getState) => {
        const workzone = { ...getState().register.workzone };
        let NYWorkZone = [];
        let NJWorkZone = [];
        if(workzone['NY']) {
            NYWorkZone = workzone['NY'].map((county) => {
                return {
                    state: 'NY',
                    county: county.value,
                };
            })    
        }
        if(workzone['NJ']) {
            NJWorkZone = workzone['NJ'].map((county) => {
                return {
                    state: 'NJ',
                    county: county.value,
                };
            })
        }
        const workZoneRequestData = NYWorkZone.concat(NJWorkZone);
        const workZoneURL = '/v1/profile/update/workzonelist';
        dispatch(showSpinner());
        return fetch(workZoneURL, postReq(workZoneRequestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                //TODO: dispatch workzone
                dispatch(receivedWorkZone(response))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved Work Zone.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }    
);

export const fetchWorkZone = history => (
    (dispatch, getState) => {
        const workZoneURL = '/v1/profile/update/workzonelist';
        return fetch(workZoneURL, getReq({}))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(receivedWorkZone(response))
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved Work Zone.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                console.error(err);
            });
    }    
);

export const fetchSaveWorkHour = history => (
    (dispatch, getState) => {
        let workhour = { ...getState().register.workHour };
        delete workhour.id;
        delete workhour.userId;
        delete workhour.date;
        const workHourURL = '/v1/profile/update/workhour';
        dispatch(showSpinner());
        return fetch(workHourURL, postReq(workhour))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(receivedWorkHour(response))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Saved Work Hour.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }
);

export const fetchRemoveWorkHour = day => (
    (dispatch) => {
        const requestData = {
            day: day,
        };
        const workHourURL = '/v1/profile/delete/workhour';
        return fetch(workHourURL, postReq(requestData))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(receivedWorkHour(response))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Updated Work Hour.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }
);

export const saveUserHourlyRate = history => (
    (dispatch, getState) => {
        let userRate = {
            rate: getState().register.rate,
        }
        const saveUserHourlyRateURL = '/v1/profile/update/rate';
        return fetch(saveUserHourlyRateURL, postReq(userRate))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                dispatch(receivedHourlyRate(response.rate))
                dispatch(hideSpinner());
                dispatch(updateNotification({
                    display: true,
                    text: 'Updated Hourly Rate.',
                    type: toast.TYPE.SUCCESS,
                }));
            })
            .catch((err) => {
                dispatch(hideSpinner());
            });
    }
);

export const fetchSearch = keyword => (
    (dispatch, getState) => {
        const search = getState().search.searchValue;
        let input = keyword || search;
        const searchURL = '/v1/search/provider?input=' + input;
        return fetch(searchURL, getReq(input))
            .then(evaluateResponseWithErrorMessage)
            .then((response) => {
                if (response) {
                    dispatch(receivedSearchProviderResult(response));
                    // history.push('/search/'+ input);
                } else {
                    console.error('provider not found');
                }
            })
            .catch((err) => {
                dispatch(hideSpinner());
                console.error(err);
            });
    }
)

export const receivedSearchProviderResult = searchResponse => ({
    type: types.RECEIVED_SEARCH_PROVIDER_RESULT, searchResponse,
})


// 	$scope.resetPassword = function() {
//         var errorMessageData = [];
// 		$http.defaults.headers.post['Content-Type'] = 'application/json;';
// 		var requestData = {
// 		    'email' : $scope.email,
//             'url' : $window.location.origin,
//         };
//         $http({
//             method: 'GET',
//             url: '/v1/password/rs',
//             data: JSON.stringify(requestData)
//         }).then(function successCallback(response) {
//             var messageData = [];
//             messageData.type = "success";
//             messageData.message = "Sent a reset password information to " + $scope.email;
//             $rootScope.$broadcast('alertMessage', messageData);
// //            $location.path('/login');
//         }, function errorCallback(response) {
//             sendErrorMessage(response.data);
//         });
// 	}

//     var sendErrorMessage = function(error) {
//         var errorMessageData = [];
//         errorMessageData.type = 'warning';
//         errorMessageData.message = 'Server is not responding. Try again!';
//         errorMessageData.console = error;
//         $rootScope.$broadcast('alertMessage', errorMessageData);
//     }
