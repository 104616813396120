import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import ProviderList from '../../components/ProviderList';
import { fetchSearch } from '../../actions/api';
import { updateMessageDialog } from '../../actions/view';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        keyword: ownProps.match.params.keyword,
        providerList: state.search.providerList,
    }
};

const mapDispatchToProps = dispatch => ({
    getProviderList: (keyword) => {
        dispatch(fetchSearch(keyword));
    },
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
});

class SearchWrapper extends Component {
    componentDidMount() {
        this.props.getProviderList(this.props.keyword);
    }

    componentDidUpdate(newProps) {
        if (newProps.keyword !== this.props.keyword) {
            this.props.getProviderList(newProps.keyword);
        }
    }

    render() {
        return (
            <section id="gete-project" className="gete-project">
                <Container maxWidth="md">    
                    <ProviderList handleClickMessageDialogOpen={this.props.handleClickMessageDialogOpen} providerList={this.props.providerList} />
                </Container>
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchWrapper);
