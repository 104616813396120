import { connect } from 'react-redux';
import ProviderList from '../../components/ProviderList';
import { getProjectProposals, getProjectStatus } from '../../selectors/ee';
import { updateMessageDialog } from '../../actions/view';
import { fetchRemoveProposal } from '../../actions/api';

const mapStateToProps = state => {
    return {
        user: state.ee.user,
        providerList: state.ee.project.providerList,
        project: state.ee.project,
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
    handleProposalDeleteClick: (proposalId) => {
        dispatch(fetchRemoveProposal(proposalId));
    },
 });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProviderList);
