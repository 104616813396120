import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Select from 'react-select'
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%'
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    flex: {        
        display: 'flex',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    marginLeft: {
        marginLeft: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    }, 
    subLabel: {
        fontWeight: 600, 
    },
    minWidth: {
        minWidth: '450px',
    }
});

const Address = (props) => {
    const {
        classes,
        user,
        register,
        history,
        isUpdate,
        handleChange,
        handleUpdateAddress,
    } = props;

    const registerError = register.error;
    const address = register.address;
    const stateOptions = register.states.map(state => ({ 
        value: state,
        label: state
    }));

    const submitDisabled = address.address === undefined || address.city === undefined || address.stateProvince === undefined ||  address.zipcode === undefined;

    const stateProvince = address.stateProvince ? {
        value: address.stateProvince,
        label: address.stateProvince,
    } : undefined;

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <InputLabel 
                    htmlFor="phone"
                    className={clsx(classes.subLabel, 'ee-color')}
                >Address</InputLabel>
                <TextField
                    required
                    id="street"
                    label="Street"
                    value={register.address.address}
                    onChange={(event) => handleChange('address', event.target.value)}
                    className={clsx(classes.wide, classes.margin, classes.flex)}
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    required
                    id="city"
                    label="City"
                    value={register.address.city}
                    onChange={(event) => handleChange('city', event.target.value)}
                    className={clsx(classes.wide, classes.margin, classes.flex)}
                    margin="normal"
                    variant="outlined"
                />
                <Select
                    required
                    name="state"
                    label="State"
                    placeholder="Select state..."
                    value={stateProvince}
                    options={stateOptions}
                    className={classes.select}
                    classNamePrefix="select"
                    onChange={(event) => handleChange('stateProvince', event.value)}
                    className={clsx(classes.wide, classes.margin)}
                    />
                <TextField
                    required
                    id="zipcode"
                    label="Zipcode"
                    value={register.address.zipcode}
                    onChange={(event) => handleChange('zipcode', event.target.value)}
                    className={clsx(classes.wide, classes.margin, classes.zIndex0, classes.flex)}
                    margin="normal"
                    variant="outlined"
                />
                {isUpdate && 
                    <div className={clsx(classes.wide, classes.margin20, classes.flex)}>      
                        <Button
                            disabled={submitDisabled}
                            variant="contained" 
                            component="span" 
                            color="green"
                            onClick={() => handleUpdateAddress(history)}
                            className={submitDisabled ? clsx(classes.wide, classes.marginLeft) : clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}
                        >
                            Save address
                        </Button>
                    </div>
                }
            </div>
        </Container>
    );
};

export default withStyles(styles)(Address);

