import { connect } from 'react-redux';
import ReviewList from '../../review/components/ReviewList';
import { getProjectReviews } from '../../selectors/ee';
import { updateMessageDialog } from '../../actions/view';

const mapStateToProps = state => {
    return {
        user: state.ee.user,
        reviews: getProjectReviews(state),
        isDashboardView: false,
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewList);
