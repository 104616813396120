import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
const styles = theme => ({
    footer: {
        fontFamily: "'Noto Sans', sans-serif",
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0',
        width: '100%',
    },
    text: {
        textAlign: 'center',
        margin: 'auto',
        verticalAlign: 'middle',
    },
});

const FooterContainer = (props) => {
    const {
        classes,
    } = props;

    return (
        <footer className={classes.footer}>
            {/* <div class="container">
                <p>© 2020 GetElectrician, Inc. · <a href="#termsOfUse">Terms of Use</a> · <a href="#codeOfEthics">Code of Ethics</a></p>
                <p class="text-muted">Place sticky footer content here.</p>
            </div> */}
            <Grid container spacing={3}
                alignItems="center"
                alignContent="center"
                justifyContent="center">
                <Grid 
                    item xs={4}
                    className={classes.text}
                >
                        <a href="aboutus">About US</a>
                </Grid>
                <Grid item xs={4}
                    className={classes.text}
                >
                    <div>
                        <a href="contact">Help and Support</a>
                    </div>
                    <div>
                        <a href="covid19">GetElectrician and covid-19</a>
                    </div>
                </Grid>
                {/* <Grid item xs={4}
                    className={classes.text}
                >
                    <div>
                        <a href="termsOfUse">Term of Use</a>
                    </div>
                    <div>
                        <a href="privacyPolicy">Privacy Policy</a>
                    </div>
                    <div>
                        <a href="ccpa">California Residents Privacy</a>
                    </div>
                    <div>
                        <a href="codeOfEthics">Code of Ethics</a>
                    </div>
                </Grid> */}
            </Grid>
            <div className={classes.text}>
                © 2025 GetElectrician.com, ALL RIGHTS RESERVED, 
                <div>
                    <a href="termsOfUse">Term of Use</a> | 
                    <a href="privacyPolicy"> Privacy Policy</a> |
                    <a href="ccpa"> California Residents Privacy</a> |
                    <a href="codeOfEthics"> Code of Ethics</a>
                </div>
            </div>
        </footer>
    )
}

export default withStyles(styles)(FooterContainer);
