import React from 'react';
// import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    fontFamily: "'Noto Sans', sans-serif",
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: "100%",
    minWidth: 700,
  },
  th: {
      paddingLeft: '2px',
      paddingRight: '2px',
  },
  title: {
      cursor: 'pointer',
      paddingLeft: '2px',
      paddingRight: '2px',
      fontWeight: '500',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

    const Insurance = (props) => {
    const {
        classes,
        rowData,
        trs,
    } = props;

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                <TableRow>
                    {trs.map(tr => (
                        <TableCell className={classes.th} >{tr}</TableCell>
                    ))}
                </TableRow>
                </TableHead>
                <TableBody>
                    {rowData.map(row => (
                        <TableRow 
                            className={classes.row} 
                            key={row.id} 
                            id='listRow'
                      >
                          {row.data.map(column => (
                            <TableCell className={classes.th} align="left">{column}</TableCell>
                          ))}
                          </TableRow>
                    ))}
                    </TableBody>
            </Table>
        </Paper>
    );
};

export default withStyles(styles)(Insurance);

