import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import IdleTimer from 'react-idle-timer'
import HomeContainer from '../containers/HomeContainer';
import TopNavContainer from '../containers/TopNavContainer';
import AboutUsContainer from '../containers/AboutUsContainer';
import Covid19Container from '../containers/Covid19Container';
import HowItWorksContainer from '../containers/HowItWorksContainer';
import HowItWorksProviderContainer from '../containers/HowItWorksProviderContainer';
import LoginContainer from '../containers/LoginContainer';
import CodeOfEthicsContainer from './CodeOfEthicsContainer';
import TermOfUseContainer from './TermOfUseContainer';
import FooterContainer from './FooterContainer';
import DashboardContainer from '../dashboard/containers/DashboardContainer';
import NotificationContainer from '../containers/NotificationContainer';
import MessageDialogContainer from '../message/containers/MessageDialogContainer';
import ProjectDetailContainer from '../project/containers/ProjectDetailContainer';
import EditProjectContainer from '../project/containers/EditProjectContainer';
import EditProposalContainer from '../proposal/containers/EditProposalContainer';
import RegisterContainer from '../register/containers/RegisterContainer';
import ProfileContainer from '../register/containers/ProfileContainer';
import PublicContainer from '../profile/containers/PublicContainer';
import WriteReviewContainer from '../project/containers/WriteReviewContainer';
import AccountActiviationContainer from '../profile/containers/AccountActiviationContainer';
import NotificationSettingContainer from '../profile/containers/NotificationSettingContainer';
import PasswordContainer from '../profile/containers/PasswordContainer';
import ForgotPasswordContainer from '../profile/containers/ForgotPasswordContainer';
import ResetPasswordContainer from '../profile/containers/ResetPasswordContainer';
import { logout } from '../actions/api';
import Loader from '../components/Loader';
import ContactContainer from '../help/containers/ContactContainer';
import PrivacyPolicyContainer from './PrivacyPolicyContainer';
import CCPAContainer from './CCPAContainer';
import MessageTabContainer from '../messageWrapper/containers/MessageTabContainer';
import QuickOfferContainer from '../proposal/containers/QuickOfferContainer';
import SearchWrapper from '../search/containers/SearchWrapper';

const mapStateToProps = (state, ownProps) => ({
    user : state.ee.user,
    history: ownProps.history,
    showSpinner: state.view.showSpinner,
});

const mapDispatchToProps = dispatch => ({
    logoutProcess: (history) => {
        dispatch(logout(history));
    },
});

class Wrapper extends React.Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    componentDidUpdate() {
        const { user, history, showSpinner } = this.props;
    }

    _onAction(e) {
        // if(e.target.innerHTML === 'Log out') {
        //     this.props.logout();
        // }
    }
     
    _onActive(e) {
        console.log('user is active', e)
    }
    
    _onIdle(history) {
        this.props.logoutProcess(history);
    }


    render() {
        const { user, history, showSpinner, onActive, onIdle, onAction } = this.props;
        let { idleTimer } = this.props;

        return (
            <Router history={history}>
                <div id="gete" className="gete">
                    <IdleTimer
                        ref={ref => { idleTimer = ref }}
                        element={document}
                        onActive={this.onActive}
                        onIdle={() => this.onIdle(history)}
                        onAction={this.onAction}
                        debounce={250}
                        timeout={1000 * 60 * 15} />
                    <NotificationContainer />
                    <MessageDialogContainer />
                    <TopNavContainer  history={history} />
                    <Loader show={showSpinner} />
                    <div className="pb100">
                        <Switch>
                            <Route exact path="/" component={HomeContainer} />
                            <Route exact path="/home" component={HomeContainer} />
                            <Route exact path="/aboutus" component={AboutUsContainer} />
                            <Route exact path="/howitworks" component={HowItWorksContainer} />
                            <Route exact path="/howitworks_provider" component={HowItWorksProviderContainer} />
                            <Route exact path="/login" component={LoginContainer} />
                            <Route exact path="/dashboard" component={DashboardContainer} />
                            <Route exact path="/create" component={HomeContainer} />
                            <Route exact path="/project/:projectId" component={ProjectDetailContainer} />
                            <Route exact path="/editProject/:projectId" component={EditProjectContainer} />
                            <Route exact path="/editProposal/:projectId" component={EditProposalContainer} />
                            <Route exact path="/quickOffer/:projectId" component={QuickOfferContainer} />
                            <Route exact path="/register/new" component={RegisterContainer} />
                            <Route exact path="/updateProfile" component={ProfileContainer} />
                            <Route exact path="/public/:pid" component={PublicContainer} />
                            <Route exact path="/writeReview/:projectId/:providerId" component={WriteReviewContainer} />
                            <Route exact path="/accountActivation" component={AccountActiviationContainer} />
                            <Route exact path="/notificationSetting" component={NotificationSettingContainer} />
                            <Route exact path="/password" component={PasswordContainer} />
                            <Route exact path="/forgotPassword" component={ForgotPasswordContainer} />
                            <Route exact path="/resetPassword/:token" component={ResetPasswordContainer} />
                            <Route exact path="/termsOfUse" component={TermOfUseContainer} />
                            <Route exact path="/codeOfEthics" component={CodeOfEthicsContainer} />
                            <Route exact path="/privacyPolicy" component={PrivacyPolicyContainer} />
                            <Route exact path="/CCPA" component={CCPAContainer} />
                            <Route exact path="/contact" component={ContactContainer} />
                            <Route exact path="/inbox" component={MessageTabContainer} />
                            <Route exact path="/covid19" component={Covid19Container} />
                            <Route exact path="/search/:keyword" component={SearchWrapper} />
                        </Switch>
                    </div>
                    <FooterContainer  history={history} />
                </div>
            </Router>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Wrapper);
