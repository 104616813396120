import * as types from '../constants/actionTypes';

export const initialState = () => ({
    accountActivation: {
        type: '',
        action: 'Deactivate',
        enabled: true,    
        message: '',
    },
    notificationSetting: {
        email: false,
    },
    password: {
        oldPassword: undefined,
        newPwasword: undefined,
        verifyNewPassword: undefined,
        code: undefined,
        enabled: false,
        verified: false,
    }
});

const queryToArray = (queryStr) => {
    const queryArr = queryStr.replace('?','').split('&');
    const queryParams = [];

    for (var q = 0, qArrLength = queryArr.length; q < qArrLength; q++) {
        var qArr = queryArr[q].split('=');
        queryParams[qArr[0]] = qArr[1];
    }

    return queryParams;
};

export default function setting(prevState = initialState(), action) {
    const state = {
        ...prevState,
    };

    switch (action.type) {
        case types.UPDATE_ACCOUNT_ACTIVATION_STATUS:
            return {
                ...state,
                accountActivation: {
                    ...state.accountActivation,
                    action: action.accountActivation.action,
                    enabled: action.accountActivation.enabled,
                    message: action.accountActivation.message,
                }
            } 
        case types.UPDATE_NOTIFICATION_SETTING:
            return {
                ...state,
                notificationSetting: action.notificationSetting,
            }           
        case types.UPDATE_NOTIFICATION_SETTING_BY_TYPE:
            if(action.name === 'email') {
                return {
                    ...state,
                    notificationSetting: {
                        ...state.notificationSetting,
                        'email': !state.notificationSetting.email,
                    }
                }                
            }
            return {
                ...state
            };
        case types.UPDATE_PASSWORD_VALUE:
            return {
                ...state,
                password: {
                    ...state.password,
                    [action.name]: action.value,
                }
            }
        case types.INIT_UPDATE_PASSWORD: {
            return {
                ...state,
                password: {
                    oldPassword: undefined,
                    newPwasword: undefined,
                    verifyNewPassword: undefined,
                    enabled: false,
                }
            }        
        }
        case types.UPDATE_TOKEN_EMAIL: {
            const queryParams = queryToArray(action.queryStr)
            return {
                ...state,
                password: {
                    ...state.password,
                    code: queryParams.c,
                    token: action.token,
                    email: queryParams.email,
                }
            }
        }
        default:
            return {
                ...state
            };
    }
}
