import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '10px 16px 10px 16px',
        marginBottom: '6px',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    lightGrey: {
        color: '#c2c2c2',
    },
    green: {
        color: '#01DF01',
    }, 
    publicTopDay: {
        float: 'right',
        padding: '1rem',
        bottom: '0',
        backgroundColor: '#F0F0F0',
    },
    publicTopLeft: {
        padding: '5px',
        margin: '5px',
    },    
    publicTopRight: {
        float: 'right',
        padding: '1rem',
        display: 'flex',
    },
    profileImage: {
        width: '200px',
        height: '200px',
    },
    top: {
        display: 'flex',
    },
    label: {
        paddingRight: '16px',
    }, 
    buttonBlock: {
        display: 'block',
    }
});

const WriteReview = (props) => {
    const {
        classes,
        history,
        handleChange,
        onSaveClick,
        review,
    } = props;

    const { 
        recommend,
        status,
        quality,
        responsiveness,
        professionalism,
        subjectMatterExpertise,
        adherenceToCost,
        adherenceToSchedule,
        comment,
    } = review;

    return (
        <Container maxWidth="lg">
        	<div className={classes.root} >
                <div className="glyphicon glyphicon-remove close" ng-click="cancelWriteReview()" />
                <div className="btn-group">
                    <label className={classes.label}>Project status </label>
                    <div>
                        <Button
                            variant={status === 'completed' ? "contained" : "outlined"}
                            component="span" 
                            onClick={() => {handleChange('status', 'completed')}}
                            className={status === 'completed' ? clsx('ee-bg-color', 'flex') : clsx('flex') }>
                            Project is completed
                        </Button>
                        <Button 
                            variant={status === 'uncompleted' ? "contained" : "outlined"}
                            component="span" 
                            onClick={() => {handleChange('status', 'uncompleted')}}
                            className={clsx('flex')}>
                            Project is not completed
                        </Button>
                    </div>
                </div>
                <hr />
                <div className="form-group">
                    <label className={classes.label}>
                        How likely is it that you would recommend this electrician(s) to a friend or a colleague?</label>
                    <div className={classes.buttonBlock}>
                        <Button
                            variant={recommend === true ? "contained" : "outlined"}
                            component="span" 
                            onClick={() => {handleChange('recommend', true)}}
                            className={recommend === true ? clsx('ee-bg-color', 'flex') : clsx('flex') }>
                            Recommend!
                        </Button>
                        <Button 
                            variant={recommend === false ? "contained" : "outlined"}
                            component="span" 
                            onClick={() => {handleChange('recommend', false)}}
                            className={clsx('flex')}>
                            No.
                        </Button>
                    </div>
                </div>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Quality</Typography>
                    <Rating
                        name="quality"
                        value={quality}
                        onChange={(event) => {
                            handleChange('quality', event.target.value);
                        }}
                    />
                </Box>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Responsiveness</Typography>
                    <Rating
                        name="responsiveness"
                        value={responsiveness}
                        onChange={(event) => {
                            handleChange('responsiveness', event.target.value);
                        }}
                    />
                </Box>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Professionalism</Typography>
                    <Rating
                        name="professionalism"
                        value={professionalism}
                        onChange={(event) => {
                            handleChange('professionalism', event.target.value);
                        }}
                    />
                </Box>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Subject Matter Expertise</Typography>
                    <Rating
                        name="subjectMatterExpertise"
                        value={subjectMatterExpertise}
                        onChange={(event) => {
                            handleChange('subjectMatterExpertise', event.target.value);
                        }}
                    />
                </Box>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Adherence To Cost</Typography>
                    <Rating
                        name="adherenceToCost"
                        value={adherenceToCost}
                        onChange={(event) => {
                            handleChange('adherenceToCost', event.target.value);
                        }}
                    />
                </Box>
                <Box component="fieldset" mb={3} borderColor="transparent">
                    <Typography component="legend">Adherence To Schedule</Typography>
                    <Rating
                        name="adherenceToSchedule"
                        value={adherenceToSchedule}
                        onChange={(event, newValue) => {
                            handleChange('adherenceToSchedule', newValue);
                        }}
                    />
                </Box>
                <TextField
                    autoFocus
                    margin="dense"
                    id="comment"
                    label="Comment"
                    type="textarea"
                    fullWidth
                    onChange={(event) => {handleChange('comment', event.target.value)}}
                    value={comment}
                />
                <div className={clsx(classes.wide, classes.margin20)}>
                    <Button 
                        variant="contained" 
                        component="span" 
                        onClick={() => onSaveClick(history)}
                        className={clsx(classes.wide, 'ee-bg-color', 'flex')}>
                        Save
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default withStyles(styles)(WriteReview);
