import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SimpleList from '../../components/SimpleList';
import ReviewListContainer from '../containers/ReviewListContainer'
import Rating from '@material-ui/lab/Rating';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
        font: '14px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    marginLeft: {
        marginLeft: '4px',
    },
    zIndex0: {
        zIndex: 0,
    },
    lightGrey: {
        color: '#c2c2c2',
    },
    green: {
        color: '#01DF01',
    }, 
    publicTopDay: {
        float: 'right',
        padding: '1rem',
        bottom: '0',
        backgroundColor: '#F0F0F0',
    },
    publicTopLeft: {
        padding: '5px',
        margin: '5px',
    },    
    publicTopRight: {
        float: 'right',
        padding: '1rem',
        display: 'flex',
    },
    profileImage: {
        width: '200px',
        height: '200px',
    },
    top: {
        display: 'flex',
    },
    paddingLeft: {
        paddingLeft: '4px',
    },
    rating: {
        fontSize: '30px',
    },
    reviewTitle: {
        width: '100%',
        padding: '0px 20px 10px 20px',
    }
});

const Public = (props) => {
    const {
        classes,
        user,
        history,
        publicReducer,
        permitList,
        insuranceList,
        providerId,
        handleClickMessageDialogOpen,
        handleSendRequestCall,
        reviewAvarage,
    } = props;

    const { 
        workHourList,
        location,
        since,
        userCompany,
        profileImage,
        name,
    } = publicReducer;
    // const formattedIssuedAt = moment(permit.issuedAt).format('YYYY-MM-DD');
    // const submitDisabled = permit.name === undefined || !formattedIssuedAt === undefined || !permit.company === undefined;

    const rate = Number(publicReducer.rateAverage);
    return (
    	<div className={classes.root} >
            <div className={classes.top}> 
                <div className={clsx(classes.profileImage, classes.publicTopLeft)}>
                    <img className="profile-image" src={profileImage} />
                </div>
                <div className={classes.publicTopLeft}>
                    <div ng-show="!showCompany">
                        <h3><strong>{name}</strong>, <span className={classes.lightGrey}>{location}</span></h3>
                    </div>
                    {userCompany && publicReducer.userType === 'plec' && <div>
                        <div><strong>{userCompany.name}</strong>, <span className={classes.lightGrey}>{location}</span></div>
                        <div>Compnay ID: {userCompany.companyId}, <span className={classes.lightGrey}>registered at {userCompany.registeredAt}</span></div>
                    </div>}
                    <div className={classes.green}>since {since} in GetElectrician</div>
                    <div><strong>{publicReducer.completedProjectCount} finished job, {publicReducer.workingProjectCount} working job,  {publicReducer.reviewCount} reviews</strong></div>
                    <div>
                        <Button
                            disabled={user.userType !== 'c'}
                            variant="contained" 
                            component="span" 
                            color="green"
                            onClick={() => handleClickMessageDialogOpen(name, providerId)}
                            className={clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}
                        >
                            Ask Question 
                        </Button>
                        <Button
                            disabled={user.userType !== 'c'}
                            variant="contained" 
                            component="span" 
                            color="green"
                            onClick={() => handleClickMessageDialogOpen(name, providerId)}
                            className={clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}
                        >
                            Send Mail
                        </Button>
                        <Button
                            disabled={user.userType !== 'c'}
                            variant="contained" 
                            component="span" 
                            color="green"
                            onClick={() => handleSendRequestCall(name, providerId)}
                            className={clsx(classes.wide, 'ee-bg-color', classes.marginLeft)}
                        >
                            Request Call
                        </Button>
                    </div>
                </div>
                <div className={classes.publicTopRight}>
                    <div>
                        <div className={classes.publicTopDay}>
                            {workHourList.map(workHour => (
                                <div key={workHour.id}>{workHour.day} : {workHour.start} ~ {workHour.end}</div>
                            ))}
                    </div>
                </div>
            </div>            
        </div>
    	<hr />
        
        <div className={classes.marginLeft}>
            <div className={clsx(classes.reviewTitle)}>
                <h3>Reviews               
                    <Rating  
                        className={clsx(classes.green, classes.rating)}
                        name="rate"
                        value={rate}
                        readOnly
                    />
                <span className={classes.paddingLeft}>{rate}</span>
                </h3>
                {publicReducer.reviewCount} reviews
            </div>
            <hr/>
             <ReviewListContainer />
        </div>
    
        <hr />
        {insuranceList && insuranceList.length > 0 &&
            <section id="gete-project" className="gete-project">
                <div>Insurance</div>
                <SimpleList
                    rowData={insuranceList}
                    trs={['Name', 'Company', 'Valid by']}
                    history={history}
                />
            </section>}
        {permitList && permitList.length > 0 &&
            <section id="gete-project" className="gete-project">
            <div>Permit</div>
            <SimpleList
                rowData={permitList}
                trs={['Name', 'Issued At', 'Issued by']}
                history={history}
            />
        </section>}
        <div>
        </div>
        </div>
    );
};

export default withStyles(styles)(Public);
