import { connect } from 'react-redux';
import ReviewList from '../../review/components/ReviewList';
import { getReviews } from '../../selectors/public';
import { updateMessageDialog } from '../../actions/view';

const mapStateToProps = state => {
    const reviews = getReviews(state)
    return {
        user: state.ee.user,
        reviews: reviews,
        messageType: 'user-message',
        trs: [{
                title: 'Eletrician Name',
                align: 'center'
                                
            }, {
                title: 'Project ID',
                align: 'center',
            }, {
                title: 'Overall score',
                align: 'center',
            }, {
                title: 'Recommend?',
                align: 'center',
            }, {
                title: 'Comments',
                align: 'center',
            }, {
                title: 'Date',
                align: 'center',
            }]
    }
};

const mapDispatchToProps = dispatch => ({
    handleClickMessageDialogOpen: (replyDisplay, replyId, projectId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
            projectId,
        }
        dispatch(updateMessageDialog(message));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewList);
