import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import EmailIcon from '@material-ui/icons/Email';
import IconButton from '@material-ui/core/IconButton';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updateSearchValue } from '../actions/view'

import { logout, fetchSearch } from '../actions/api';
import SearchIconSvg from '../icons/SearchSvg';

const mapStateToProps = (state, ownProps) => ({
    user : state.ee.user,
    searchValue: state.search.searchValue,
    history: ownProps.history,
    location: ownProps
});

const mapDispatchToProps = dispatch => ({
    logoutProcess: (history) => {
        dispatch(logout(history));
    },
    updateSearch: (value) => {
        dispatch(updateSearchValue(value));
    },
    handleSearchSubmit: (history) => {
        dispatch(fetchSearch(history));
    }
});


class TopNavContainer extends Component {
    render() {
        const { user, searchValue, history } = this.props;
        const registerStep = this.props.register && this.props.register.registerStep || this.props.user.step;
        const showCompleteProfile = (user.userType !== 'c' && user.userType !== 's') && registerStep < 3;
        const publicPath = `/public/${user.id}`;
        const searchPath = `/search/${searchValue}`;

        return (
            <div className="nav-wrapper">
                <Navbar collapseOnSelect expand="lg" variant="dark" className="nav-ee container" sticky="top">
                <Navbar.Brand href="/" className="grey">GetElectrician
                    <span className="navbar-text">
                        Beta
                    </span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/aboutus" className="ee-color">ABOUT US</Nav.Link>
                        <Nav.Link href="/howitworks" className="ee-color">HOW IT WORKS</Nav.Link>
                        {user.userType !== 's' && <Nav.Link href="/dashboard" className="ee-color">DASHBOARD</Nav.Link>}
                        {(user.userType === 'c' || user.userType === 'a') && <Nav.Link href="/editProject/new">Create New Project</Nav.Link>}
                        <Form inline className='pd-left-sm'>
                            <Row>
                                <Col xs="auto">
                                    <Form.Control
                                        id="search"
                                        type="text"
                                        placeholder="Search"
                                        value={searchValue}
                                        onChange={(event) => this.props.updateSearch(event.target.value)}
                                        // className="mr-sm-2"
                                    />
                                    {/* <Button 
                                        size="small" 
                                        className="btn-ee-primary"
                                        onClick={() => this.props.handleSearchSubmit(history)}
                                        id="icon"
                                    > */}
                                    <Nav.Link href={searchPath} className='display-inline'><SearchIconSvg /></Nav.Link>
                                        
                                    {/* </Button> */}
                                </Col>
                            </Row>
                        </Form>                        
                    </Nav>
                    <div className="flex">
                        {user.userType === 's' && <Nav.Link href="/register/new" className="ee-color">Register</Nav.Link>}
                        {user.userType === 's' && <Nav.Link href="/login" className="ee-color">Sign in</Nav.Link>}
                        {user.userType === 'a' && <Nav.Link href="admin">Admin</Nav.Link>}
                        {user.userType !== 's' && 
                                <IconButton aria-label="inbox" href="/inbox" >
                                    <EmailIcon  className="ee-color" />
                              </IconButton>
                        }  
                        {user.userType !== 's' && 
                            <NavDropdown title={user.userDisplayName} className="ee-color pt4" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/accountActivation">Account Activation</NavDropdown.Item>
                                <NavDropdown.Item href="/notificationSetting">Notification Setting</NavDropdown.Item>
                                <NavDropdown.Item href="/password">Update Password</NavDropdown.Item>
                                <NavDropdown.Item href="/updateProfile">Update Profile</NavDropdown.Item>
                                { showCompleteProfile && <NavDropdown.Item href="/register/new">Complete Profile</NavDropdown.Item>}
                                {(user.userType !== 'c' && user.userType !== 's') && <NavDropdown.Item href={publicPath}>My Public Profile</NavDropdown.Item>}       
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => { this.props.logoutProcess(this.props.history) }}>Log out</NavDropdown.Item >
                            </NavDropdown> 
                        }
                    </div>
                </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavContainer);
