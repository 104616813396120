import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { formatPhoneNumber } from '../util/phoneUtil';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '16px',
        boarder: 'solid',
        border: 'solid 1px rgb(60,64,67)',
        borderRadius: '12px',
        overflow: 'hidden',
        marginBottom: '6px',   
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    project: {
        cursor: 'pointer',
        paddingLeft: '2px',
        paddingRight: '2px',
        fontWeight: '500',
    },
    block: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
    },
    name: {
        fontSize: '22px',
        // color: '#3cb63c',
        weight: 200,
    },
    option: {
        paddingRight: '8px',
        marginRgiht: '10px!important',
        alignItems: 'center',
    },
    "option:before": {
        // content: '\0022c5',
        marginLeft: '4px',
        marginRight: '4px',    
    },
    bgColorGreen: {
        // backgroundColor: '#3cb63c',
        // color: '#FFFFFF',
    },
    bgColorGrey: {
        // backgroundColor: 'rgba(0, 0, 0, 0.12)',
        // color: '#FFFFFF',
    },
    icon: {
        // height: '0.8em',
        fontSize: '20px',
        width:'unset'
    },
    w400: {
        weight: 400,
    },
    divider: {
        marginRgiht: '16px',
        marginLeft: '16px',
    },
    paddingRight: {
        paddingRight: '6px'
    },
    paddingLeft: {
        paddingLeft: '6px'
    },
    pr16: {
        paddingRight: '16px'
    },
    yellow: {
        color: '#f5b042',
    },
    width100: {
        width: '500px'
    },
    bb: {
        paddingBottom: '16px',
        marginBottom: '-16px',
        width: '100%',
        borderBottom: 'solid 1px rgb(60,64,67)',
        // borderBottom: 'solid 1px red',
    },
    label: {
        fontSize: '11px',
        fontWeight: '800',
        paddingRight: '6px',
    }, 
    buttongroup: {
        padding: '0 4px 0 6px',
        height: '24px',
        alignItems: 'center',
    }
});

const ProviderList = (props) => {
    const {
        classes,
        project,
        providerList,
        handleClickMessageDialogOpen,
    } = props;
    
    return (
        <div className={clsx(classes.block, classes.root)}>
            {
                providerList.map(provider => (
                <div className={clsx(classes.width100, classes.bb)} >
                    <div className={classes.block}>
                        <span className={clsx(classes.name, 'ee-color')}>
                            {provider.displayName} 
                        </span>
                        <FavoriteIcon className={classes.icon} />
                        <FavoriteBorderIcon className={classes.icon} />
                        <ButtonGroup className={clsx(classes.buttongroup, "flex")}>
                            <Button
                                onClick={() => handleClickMessageDialogOpen(provider.displayName, provider.id, project.id)}
                                classes={{
                                    label: classes.label, 
                                }}
                            >
                            Send Message
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className={classes.block}>
                        <div className={classes.paddingRight}>4.8
                            <StarIcon className={clsx(classes.icon, classes.yellow)} />
                            <StarIcon className={clsx(classes.icon, classes.yellow)} />
                            <StarIcon className={clsx(classes.icon, classes.yellow)} />
                            <StarIcon className={clsx(classes.icon, classes.yellow)} />
                            <StarHalfIcon className={clsx(classes.icon, classes.yellow)} />
                        </div>
                        <div>(123)</div>
                    </div>
                    <div className={classes.block}>
                        {provider.company && 
                            <span className={clsx(classes.option, classes.bgColorGreen)}>
                                company <CheckBoxIcon className={classes.icon} />
                            </span>
                        }
                        {!provider.company && 
                            <span className={clsx(classes.option, classes.bgColorGrey)}>
                                company <CheckBoxOutlineBlankIcon className={classes.icon} />
                            </span>
                        }
                            
                        {provider.license && 
                            <span className={clsx(classes.option, classes.bgColorGreen)}>
                                License <CheckBoxIcon />
                            </span>
                        }
                        {!provider.License && 
                            <span className={clsx(classes.option, classes.bgColorGrey)}>
                                License <CheckBoxOutlineBlankIcon className={classes.icon} />
                            </span>
                        }
                        {provider.insurance && 
                            <span className={clsx(classes.option)}>
                                Insurance <CheckBoxIcon />
                            </span>
                        }
                        {!provider.insurance && 
                            <span className={clsx(classes.option, classes.bgColorGrey)}>
                                Insurance <CheckBoxOutlineBlankIcon className={classes.icon} />
                            </span>
                        }
                    </div>
                    <div className={clsx(classes.pr16, classes.block)}>
                        <div className={classes.paddingRight}> Open 24 Hours </div> | 
                        <div className={clsx(classes.paddingRight, classes.paddingLeft)}>{formatPhoneNumber(provider.tel)}</div>
                        <div className={clsx(classes.paddingRight, classes.paddingLeft)}>{provider.website}</div>
                    </div>
                </div>
            ))
        }
        </div>
    );
};

export default withStyles(styles)(ProviderList);




