
import * as types from '../constants/actionTypes';

export const initialState = () => ({
    company: false,
    license: false,
    insurance: false,
    permit: false,
    keyword: '',
    providerList: [],
    searchValue: '',
});

export default function searchReducer(prevState = initialState(), action) {
    const state = {
        ...prevState,
    };

    switch (action.type) {
        case types.UPDATE_SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.value,
            }
        case types.RECEIVED_SEARCH_PROVIDER_RESULT:
            return {
                ...state,
                providerList: action.searchResponse.providerList,
            };
        default:
            return {
                ...state
            };
    }
}