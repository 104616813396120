import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMessageDialog } from '../../actions/view';
import { fetchGetNotificationSettingStatus, fetchSaveNotificationSetting } from '../../actions/api';
import NotificationSetting from '../components/NotificationSetting';
import { updateNotificationSettingByType } from '../../actions/setting';

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.ee.user,
        notificationSetting: state.setting.notificationSetting,
        history: ownProps.history,
    }
};

const mapDispatchToProps = dispatch => ({
    getNotificationSettingStatus: () => {
        dispatch(fetchGetNotificationSettingStatus());
    },
    handleNotificationSettingChange: (name, event) => {
        dispatch(updateNotificationSettingByType(
            name, 
            event.target.value
        ));
    },
    handleSaveNotificationSetting: (type) => {
        dispatch(fetchSaveNotificationSetting(type));
    },
    handleClickMessageDialogOpen: (replyDisplay, replyId) => {
        const message = {
            open: true,
            replyDisplay,
            replyId,
        }
        dispatch(updateMessageDialog(message));
    },
});

class NotificationSettingContainer extends Component {
    componentDidMount() {
        this.props.getNotificationSettingStatus();
    }
    
    componentWillReceiveProps(newProps) {
    }

    render() {
        return (
            <NotificationSetting 
                user={this.props.user}
                notificationSetting={this.props.notificationSetting}
                handleSaveNotificationSetting={this.props.handleSaveNotificationSetting}
                handleNotificationSettingChange={this.props.handleNotificationSettingChange}
                history={this.props.history}/>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationSettingContainer);