import { createSelector } from 'reselect';
import moment from 'moment';

const getRegisterInsurance = state => state.register.insurance;
// const getRegisterPermit = state => state.publicReducer.permitList;
const getRegisterPermit = state => {
    return state.publicReducer.permitList;
}
const getWorkHourList = state => state.publicReducer.workHourList;
const getPublicReviews = state => state.publicReducer.reviews;

export const getInsurance = createSelector(
    getRegisterInsurance,
    (insuranceList) => {
        return insuranceList.map(insurance => {
            return {
                id: insurance.id,
                data: [
                    insurance.name,
                    insurance.company,
                    insurance.validUntil,
                ]
            };
        })
    }
);

export const getPermit = createSelector(
    getRegisterPermit,
    (permitList) => {
        return permitList.map(permit => {
            return {
                id: permit.id,
                data: [
                    permit.name,
                    permit.issuedAt,
                    permit.issuedBy,
                ]
            };
        })
    }
);

export const getWorkHour = createSelector(
    getWorkHourList,
    (workHourList) => {
        return workHourList.map(workHour => {
            return {
                day: workHour.day,
                hour: workHour.start 
            };
        })
    }
);

export const getReviews = createSelector(
    getPublicReviews,
    (reviews) => {
        const parsedReviews = reviews.map((review) => {
            let processed = {};
            // processed.key = key;
            processed.id = review.id;
            processed.displayName = review.displayName;
            processed.projectId = review.projectId;
            processed.scopeId = review.scopeId;
            processed.recommend = review.recommend;
            processed.quality = review.quality;
            processed.responsiveness = review.responsiveness;
            processed.professionalism = review.professionalism;
            processed.subjectMatterExpertise = review.subjectMatterExpertise;
            processed.adherenceToCost = review.adherenceToCost;
            processed.adherenceToSchedule = review.adherenceToSchedule;
            processed.comment = review.comment;
            processed.overall = review.overall;
            processed.date = moment(review.date).format('LL');
            return processed;
        });

        return parsedReviews;
    }
);