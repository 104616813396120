import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import DeleteIconSvg from '../../icons/DeleteIconSvg';
import EditIconSvg from '../../icons/EditIconSvg';
import { Link } from '@material-ui/core';

const styles = theme => ({
  root: {
    fontFamily: "'Noto Sans', sans-serif",
    width: '100%',
    overflowX: 'auto',
    paddingBottom: '100px'
  },
  table: {
    width: "100%",
    minWidth: 700,
  },
  th: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  title: {
    cursor: 'pointer',
    paddingLeft: '2px',
    paddingRight: '2px',
    fontWeight: '600',
    color: '#3cb63c',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

//if user is defined redirect to dashboard
const ProjectList = (props) => {
    const {
        classes,
        user,
        projects,
        trs,
        handleClickRemoveProject,
        history,
    } = props;

    const editProject = (project, user) => {
        const editProjectEnabled = (project.status !== 'COMPLETED' && project.status !== 'CLOSED' && project.status !== 'ARCHIVED' && !user.userType.includes('p')) ? true : false;
        if(editProjectEnabled) {
            return <Fab 
                size="small" 
                aria-label="Delete" 
                className={classes.icon} 
                onClick={() => { history.push(`/editProject/${project.id}`) }}
                id="icon"
            >
                <EditIconSvg />
            </Fab>

        } 
        return "";
    }

    let filteredTrs = trs;
    if (user.userType.includes("p")) {
        filteredTrs = trs.filter(function(e) { return e !== 'Proposal #' })
    }
    return (
        <Paper classname={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                <TableRow>
                    {filteredTrs.map(tr => {
                        const align = tr === 'Proposal #' || tr === 'Actions' || tr === 'ID' ? 'center' : 'left';
                            return <TableCell className={classes.th} align={align}>{tr}</TableCell>
                    })}
                </TableRow>
                </TableHead>
                
                <TableBody>
                    {
                        projects.map(row => {
                            if (user.userType !== 's' && user.userType.includes("p")) {
                                return (
                                    <TableRow 
                                    className={classes.row} 
                                    key={row.id} 
                                    id='listRow'
                                >
                                    <TableCell 
                                        className={classes.th} 
                                        component="th" 
                                        scope="row"
                                    >{row.id}
                                    </TableCell>
                                    <TableCell 
                                        className={classes.title} 
                                        align="left"
                                        hover="true"
                                        onClick={() => { history.push(`/project/${row.id}`) }}
                                    >{row.title}</TableCell>
                                    <TableCell className={classes.th} align="left" >{row.createdDate}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.updatedDate}</TableCell>
                                    <TableCell className={classes.th} align="left">{row.endBiddingDate}</TableCell>
                                    <TableCell className={classes.th} align="center">
                                        {editProject(row, user)}
                                        <Fab 
                                            size="small" 
                                            aria-label="Delete" 
                                            className={classes.icon} 
                                            onClick={() => handleClickRemoveProject(row.id, history)}
                                            id="icon"
                                        >
                                            <DeleteIconSvg />
                                        </Fab>
                                    </TableCell>
                                </TableRow>
                                );
                            }
                            return (
                                <TableRow 
                                className={classes.row} 
                                key={row.id} 
                                id='listRow'
                            >
                                <TableCell 
                                    className={classes.th} 
                                    component="th" 
                                    scope="row"
                                >
                                    {row.id}
                                </TableCell>
                                <TableCell 
                                    className={classes.title} 
                                    align="left"
                                    hover="true"
                                    onClick={() => { history.push(`/project/${row.id}`) }}
                                ><Link>{row.title}</Link></TableCell>
                                <TableCell className={classes.th} align="left">{row.createdDate}</TableCell>
                                <TableCell className={classes.th} align="left">{row.updatedDate}</TableCell>
                                <TableCell className={classes.th} align="left">{row.endBiddingDate}</TableCell>
                                <TableCell className={classes.th} align="center">{row.proposalCount}</TableCell>
                                <TableCell className={classes.th} align="center">
                                    {editProject(row, user)}
                                    <Fab 
                                        size="small" 
                                        aria-label="Delete" 
                                        className={classes.icon} 
                                        onClick={() => handleClickRemoveProject(row.id, history)}
                                        id="icon"
                                    >
                                        <DeleteIconSvg />
                                    </Fab>
                                </TableCell>
                            </TableRow>

                            );
                        })
                    }
                </TableBody>
            </Table>
        </Paper>
    );
};

export default withStyles(styles)(ProjectList);

