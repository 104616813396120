import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import Container from '@material-ui/core/Container';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    subLabel: {
        fontWeight: 600, 
    },
});

const AddCompany = (props) => {
    const {
        classes,
        register,
        history,
        isUpdate,
        handleChange,
        handleSaveCompany,
    } = props;

    const company = register.company;
    const formattedRegisteredAt = moment(company.registeredAt).format('YYYY-MM-DD');
    const submitDisabled = company.name === undefined || !formattedRegisteredAt === undefined || !company.companyId === undefined;

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <InputLabel 
                    htmlFor="phone"
                    className={clsx(classes.subLabel, 'ee-color')}
                >Company</InputLabel>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="name"
                        label="Company name"
                        value={company.name}
                        onChange={(event) => handleChange('name', event.target.value)}
                        className={clsx(classes.wide, classes.zIndex0)}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                <div className={clsx(classes.margin20)}>
                    <TextField
                        required
                        id="registeredAt"
                        label="Date Registered"
                        type="date"
                        variant="outlined"
                        value={formattedRegisteredAt}
                        defaultValue="2017-05-24"
                        onChange={(event) => handleChange('registeredAt', event.target.value)}
                        className={clsx(classes.margin, classes.zIndex0)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className={clsx(classes.margin20, classes.zIndex0)}>
                    <TextField
                        required
                        id="companyId"
                        label="Company id"
                        value={company.companyId}
                        onChange={(event) => handleChange('companyId', event.target.value)}
                        className={clsx(classes.wide, classes.zIndex0)}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                {isUpdate && 
                    <div className={clsx(classes.wide, classes.margin20)}>      
                        <Button
                            disabled={submitDisabled}
                            variant="contained" 
                            component="span"
                            onClick={() => handleSaveCompany(history)}
                            className={!submitDisabled ? clsx(classes.wide, 'ee-bg-color', 'flex') : clsx(classes.wide, 'flex')}
                            >
                            Save company
                        </Button>
                    </div>
            }
            </div>
        </Container>
    );
};

export default withStyles(styles)(AddCompany);

