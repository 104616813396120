import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PRIVACY_NOTICE_FOR_CALIFORNIA_RESIDENTS } from '../register/components/ccpa';
import parse from 'html-react-parser';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        padding: '10px',
    },
});


const CCPAContainer = (props) => {
    const {
        classes,
    } = props;

    return (
        <div class={classes.root}>
            {parse(PRIVACY_NOTICE_FOR_CALIFORNIA_RESIDENTS)}
        </div>
    )
}

export default withStyles(styles)(CCPAContainer);