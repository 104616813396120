import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0',
        font: '14px',
    },
    formBox: {
        padding: '0px 20px 10px 20px',
    },
    wide: {
        width: '100%',
        maxWidth: '340px'
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
    },
    margin20: {
        marginBottom: '20px',
    },
    zIndex0: {
        zIndex: 0,
    },
    lightGrey: {
        color: '#c2c2c2',
    },
    green: {
        color: '#01DF01',
    }, 
    publicTopDay: {
        float: 'right',
        padding: '1rem',
        bottom: '0',
        backgroundColor: '#F0F0F0',
    },
    publicTopLeft: {
        padding: '5px',
        margin: '5px',
    },    
    publicTopRight: {
        float: 'right',
        padding: '1rem',
        display: 'flex',
    },
    profileImage: {
        width: '200px',
        height: '200px',
    },
    top: {
        display: 'flex',
    }
});

const NotificationSetting = (props) => {
    const {
        classes,
        notificationSetting,
        handleSaveNotificationSetting,
        handleNotificationSettingChange,
    } = props;

    const {
        email,
    } = notificationSetting;

    return (
        <Container maxWidth="lg">
            <div className={classes.root} >
                <h4 className="title-ee">Notification Setting</h4>
                <div className="container" id="notificationSetting">
                    <br />
                    <div name = "notificationSetting">
                        <div>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={email}
                                    onChange={(event) => handleNotificationSettingChange('email', event)}
                                    value="email"
                                    labelPlacement="start"
                                    color="success"
                                />
                                }
                                label="Email"
                            />
                        </div>
                        <Button error
                            // disabled={disabled}
                            variant="contained" 
                            component="span" 
                            onClick={() => handleSaveNotificationSetting(email)}
                            className={clsx(classes.wide, 'ee-bg-color', 'flex')}>
                            Save Notification Setting
                        </Button>                    
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default withStyles(styles)(NotificationSetting);
