import { combineReducers } from 'redux';
import view from './view';
import ee from './ee';
import register from './register';
import publicReducer from './public';
import setting from './setting';
import search from './search';

export default combineReducers({
    view,
    ee,
    register,
    publicReducer,
    setting,
    search,
});
