import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Select from 'react-select'
import Button from '@material-ui/core/Button';
import ProposalScopeListContainer from '../../project/containers/ProposalScopeListContainer';
import moment from 'moment';


const styles = theme => ({
    container: {
        maxWidth: '680px',
    },
    wide: {
        width: '355px',
    },
    narrow: {
        width: 200,
    },
    dense: {
        marginTop: 19,
    },
    margin: {
        marginLeft: '4px',
        marginRight: '4px',
        display: 'flex',
    },
    scope: {
        width: '355px',
    },
    margin20: {
        marginBottom: '20px',
    },
    marginTop20: {
        marginBottom: '20px',
    }
});

const EditProposal = (props) => {
    const {
        classes,
        user,
        projectId,
        scopes,
        proposal,
        handleChange,
        handleScopeValueChange,
        handleSelectScopeClick,
        handleScopeType,
        handleAddProposalScope,
        handleCancelProposalScope,
        handleAddProposal,
        handleCancelProposal,
    } = props;
    const history = useHistory();

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };
    const addScope = proposal.addScope;
    const selectedScopeType = addScope.type;
    const scopeTypeOptions = [
        {
            value: 'fixed_price',
            label: 'Fixed price',
        },
        {
            value: 'hourly',
            label: 'Hourly',
        }

    ];

    const scopeSelected = (addScope && addScope.scopeId && addScope.description) ? true : false;
    const defaultExpectStartDate = moment().format("YYYY-MM-DD")
    const userRate = user.rate;
    const showScopeDescription = proposal.unSelectedScopes.length > 0 ? true : false;
    const disabled = proposal.scopeDetailList < 1 || proposal.expectTime <= 0;
    return (
        <Container maxWidth="lg" className={classes.container}>
            <form className={classes.container} noValidate autoComplete="off">
                <div className={clsx(classes.margin20)}>
                    {showScopeDescription && <InputLabel 
                        className={clsx(classes.wide)}
                        htmlFor="select-multiple-chip">Select Scope to apply proposal</InputLabel>}
                    <div 
                        className={clsx(classes.wide, classes.margin20)}>
                        {proposal.unSelectedScopes.map(scope => (
                            <Button 
                                key={scope.id}
                                variant={scope.selected ? 'contained' : 'outlined'} 
                                size="small" 
                                color="primary" 
                                onClick={() => handleSelectScopeClick(scope.scopeId, scope.description)}
                                className={classes.scope}>
                                {scope.description}
                            </Button>
                        ))}
                    </div>
                </div>
                {scopeSelected && 
                    <div id="scope-detail">
                        <div className={clsx(classes.margin20)}>
                            <InputLabel 
                                className={clsx(classes.wide)}
                                htmlFor="select-multiple-chip">Scope type</InputLabel>
                            <FormControl 
                                className={clsx(classes.wide)}>
                                <Select
                                    required
                                    name="project-scopes"
                                    value={selectedScopeType}
                                    options={scopeTypeOptions}
                                    className={classes.scope}
                                    classNamePrefix="select"
                                    onChange={(selection) => {
                                        handleScopeType(selection);
                                    }}
                                />
                            </FormControl>
                        </div>
                        {selectedScopeType.value === 'hourly' && <div className={clsx(classes.margin20)}>
                            <TextField
                                required
                                id="material"
                                className={clsx(classes.narrow, classes.margin)}
                                variant="outlined"
                                label="Material Cost"
                                value={addScope.material}
                                onChange={(event) => handleScopeValueChange('material', event)}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </div>}
                        {selectedScopeType.value === 'fixed_price' && <div className={clsx(classes.margin20)}>
                            <TextField
                                required
                                id="cost"
                                className={clsx(classes.narrow, classes.margin)}
                                variant="outlined"
                                label="Price (per unit)"
                                value={addScope.cost}
                                onChange={(event) => handleScopeValueChange('cost', event)}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                        </div>}
                        {selectedScopeType.value === 'fixed_price' && <div className={clsx(classes.margin20)}>
                            <TextField
                                required
                                id="unit"
                                className={clsx(classes.narrow, classes.margin)}
                                variant="outlined"
                                label="Unit"
                                value={addScope.unit}
                                onChange={(event) => handleScopeValueChange('unit', event)}
                                type="number"
                                helperText="Enter # of unit"
                            />
                        </div>}
                        <div className={clsx(classes.margin20)}>
                            <TextField
                                required
                                id="hour"
                                className={clsx(classes.narrow, classes.margin)}
                                variant="outlined"
                                label="Hour"
                                value={addScope.hour}
                                onChange={(event) => handleScopeValueChange('hour', event)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>,
                                }}
                            />
                        </div>
                        <div className={clsx(classes.margin20)}>
                            <TextField
                                id="comment"
                                label="Comments"
                                variant="outlined"
                                multiline
                                maxRows="4"
                                minRows="4"
                                value={addScope.comment || ''}
                                onChange={(event) => handleScopeValueChange('comment', event)}
                                className={clsx(classes.wide, classes.margin)}
                                margin="normal"
                                helperText= "Enter the comments"
                            />
                        </div>
                        <Button
                            onClick={() => handleAddProposalScope(userRate)}
                        >Add scope</Button>
                        <Button
                            onClick={() => handleCancelProposalScope()}
                        >Cancel</Button>
                    </div>    
                }
                {proposal.scopeDetailList && 
                    <ProposalScopeListContainer scopeDetailList={proposal.scopeDetailList} isEdit={true} />
                }
                <div className={clsx(classes.margin20)}>
                    <TextField
                        required
                        id="startDate"
                        label="Start date"
                        className={clsx(classes.narrow, classes.margin)}
                        variant="outlined"
                        type="date"
                        defaultValue={defaultExpectStartDate}
                        value={proposal.startDate}
                        onChange={(event) => handleChange('startDate', event)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className={clsx(classes.margin20)}>
                    <TextField
                        required
                        id="expectTime"
                        className={clsx(classes.narrow, classes.margin)}
                        variant="outlined"
                        label="Total Expect Time (unit day(s))"
                        type="Number"
                        defaultValue="3"
                        value={proposal.expectTime}
                        onChange={(event) => handleChange('expectTime', event)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 1, // 1 hour
                        }}
                    />
                </div>
                <div className={clsx(classes.margin20)}>
                    <TextField
                        id="comment"
                        label="Comments"
                        variant="outlined"
                        multiline
                        maxRows="4"
                        minRows="4"
                        value={proposal.comment}
                        onChange={(event) => handleChange('comment', event)}
                        className={clsx(classes.wide, classes.margin)}
                        margin="normal"
                        helperText= "Write additional info..."
                    />
                </div>

            <div className={clsx(classes.wide, classes.margin20)}>        
            <Button 
                disabled={disabled}
                variant="contained" 
                component="span" 
                onClick={() => handleAddProposal(history)}
                className={disabled ? clsx(classes.wide, 'flex') : clsx(classes.wide, 'ee-bg-color', 'flex')}
                >
                Save
            </Button>
            <Button 
                variant="contained" 
                component="span" 
                onClick={history.goBack}
                className={clsx(classes.wide, 'ee-color', 'flex')}>
                Cancel
            </Button>
            </div>
        </form>
    </Container>
  );
}

export default withStyles(styles)(EditProposal);