import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CheckedIconSvg from '../../icons/CheckedIconSvg';
import UncheckedIconSvg from '../../icons/UncheckedIconSvg';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        fontSize: '15px',
        fontColor: '#222222',
        padding: '0px 20px 10px 20px',
        marginBottom: '20px'
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    project: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '16px',
    },
    title: {
        color: '#01DF01',
        weight: 200,
        fontSize: '22px',    },
    removed: {
        color: '#FF4500'
    },
    grey: {
        color: '#222',
    },
    scopes: {
        display: 'inline',
    },
    scope: {
        backgroundColor:'#F2F2F2',
        margin: theme.spacing.unit,
        color: 'black !important',
    },
    selectedScope: {
        display: 'inline',
    },
    label: {
        fontSize: '12px',
        fontWeight: '800',
        paddingRight: '6px',
    }, 
    creationInfo: {
        paddingBottom: '15px',
    },
    checkbox: {
        display: 'inline-flex',
        paddingRight: '24px'
    },
    select: {
      display: 'inline-flex',
      fontSize: '18px',
      border: 'solid 1px #c2c2c2',
      borderRadius: '18px',
      backgroundColor: '#3cb63c7a',
      height: '45px',
      margin: '10px 50px'
    },
    saveSelect: {
        display: 'inline-flex',
        fontSize: '18px',
        height: '45px',
        margin: '10px 50px',
        backgroundColor: '#3cb63c7a'
    },
    selectDisabled: {
        color: 'rgba(0, 0, 0, 0.26) !important',
        boxShadow:'none !important',
        boarder: '0px',
        backgroundColor: '#f2f2f2',
    },
    selectContent: {
      padding: '6px 25px 6px 25px',
      border: '0px',
    },
    buttons: {
        display: 'inline-flex',
        width: '50%',
    },
    completed: {
        marginLeft: '6px',
    },
});

const ProjectDetail = (props) => {
    const {
        classes,
        user,
        project,
        history,
        isSelect,
        showCompleted,
        handleEditProjectClick,
        handleCloseProjectClick,
        handleReopenProjectClick,
        handleProjectDeleteClick,
        handleScopeSelectSave,
        dialogOpen,
        handleDialogOpen,
        handleDialogClose,
        handleClickMessageDialogOpen,
    } = props;


    const showEditProject = ((project.status === 'CREATED' || project.status === 'UPDATED' || project.status === 'REOPENED') && !user.userType.includes('p'))? true: false;
    const showCloseProject = ((project.status === 'CREATED' || project.status === 'UPDATED' || project.status === 'REOPENED') && !user.userType.includes('p'))? true: false;
    const showReopenProject = (project.status === 'CLOSED' && !user.userType.includes('p'))? true: false;
    const showOffer = ((project.status === 'CREATED' || project.status === 'UPDATED' || project.status === 'REOPENED') && user.userType.includes('p'))? true: false;
    const SELECTED = project.status === 'SELECTED';
    const showWriteReview = (SELECTED || project.status === 'COMPLETED' || project.status === 'INCOMPLETED' )&& !user.userType.includes('p');
    let selectedCount = 0;
    project.scopes.map(addedScope => {
        if(addedScope && addedScope.providerDisplayName) {
            return selectedCount++;
        }
        return;       
    });
    const unselectedScopeList = project.scopes.map(addedScope => {
        if(!addedScope.providerDisplayName) {
            return addedScope.name;
        }
    });
    const editProposalPath = '/editProposal/' + project.id
    return (
            <Paper className={classes.root}>
                {project.status === 'SELECTED' && <kbd>PROPOSAL SELECTED</kbd>}
                {project.status !== 'SELECTED' && <kbd>{project.status}</kbd>}
                {project.completed && <kbd className={classes.completed}>COMPLETED</kbd>}

                <div className={classes.project}>
                    <div>
                        <span className={classes.title}>project no: {project.id} </span>        
                    </div>
                    <ButtonGroup className="flex">
                        {showEditProject && <Button
                            onClick={() => { history.push(`/editProject/${project.id}`) }}
                            classes={{
                                label: classes.label, 
                            }}
                        >
                        Edit
                        </Button>}
                        {showCloseProject && <Button
                            onClick={() => handleCloseProjectClick(project.id)}
                            classes={{
                                label: classes.label, 
                            }}
                        >
                        Close
                        </Button>}
                        {/* {showReopenProject && <Button
                            onClick={() => handleReopenProjectClick(project.id)}
                            classes={{
                                label: classes.label, 
                            }}
                        >
                        Reopen
                        </Button>} */}
                        {showCloseProject && <Button
                            onClick={() => handleProjectDeleteClick(project.id, history)}
                            classes={{
                                label: classes.label, 
                            }}
                        >
                        Delete
                        </Button>}
                    </ButtonGroup>
                    {showOffer && <div className='buttons'>
                        <ButtonGroup className="flex">
                            {/* <Button
                                href={quickOfferPath} 
                                classes={{
                                    label: classes.label, 
                                }}
                                ariant="contained"
                            >
                            Quick Offer 
                            </Button> */}
                            <Button
                                href={editProposalPath}
                                classes={{
                                    label: classes.label, 
                                }}
                            >
                            Write Proposal
                            </Button>
                            <Button
                                onClick={() => handleClickMessageDialogOpen(project.ownerDisplayName, project.owner, project.id)}
                                classes={{
                                    label: classes.label, 
                                }}
                            >
                            Send Message
                            </Button>
                        </ButtonGroup>
                    </div>}
                </div>

                <div className={classes.creationInfo}>Created at {project.createdDate}   last update : {project.updatedDate}</div>
                {project.status==='CLOSED' && <h6 className='removed'>This project is closed by owner. You have limited access.</h6>}
                {project.status==='ARCHIVED' &&<h6 className='removed'>This project is removed by owner. You have limited access.</h6>}
                <Divider />

                <div className={classes.scopes}>
                    {/* <label className={classes.label}>Scope(s):</label> */}
                {project.scopes.map(addedScope => (
                    <div>
                        <Button variant="outlined" size="small" className={classes.scope} disabled>
                            {addedScope.name}
                        </Button>
                        {isSelect && <div className={classes.selectedScope}>
                            ---- 
                            {addedScope.providerDisplayName && 
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    className={clsx(classes.scope, 'ee-bg-color')}>
                                    provider "{addedScope.providerDisplayName}" selected
                                </Button>                        
                            }
                            {!addedScope.providerDisplayName && 
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    className={clsx(classes.scope)}>
                                    provider unselected
                                </Button>                        
                            }
                        </div>}
                    </div>
                ))}
                    {isSelect && <div className={classes.saveSelect}>
                        <Button variant="outlined" 
                            disabled={selectedCount > 0 ? false : true}
                            className={selectedCount > 0 ? classes.selectContent : clsx(classes.selectDisabled, classes.selectContent)} onClick={() => handleDialogOpen()}
                        >Save selection</Button>
                    </div>}
                    <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Confirm
                        </DialogTitle>
                        <DialogContent>
                            {selectedCount === 0 && 
                                <DialogContentText>
                                    No scope selected: {unselectedScopeList}
                                </DialogContentText>
                            }
                            {selectedCount !== 0 && 
                                <DialogContentText>
                                    Do you want to select proposal now? We will send providers' information and you cannot reselect proposal. 
                                </DialogContentText>
                            }   
                        </DialogContent>
                        <DialogActions>
                        <Button autoFocus onClick={handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        {!unselectedScopeList && <Button onClick={() => {handleScopeSelectSave(project.id)}} color="primary">
                            Confirm
                        </Button>}
                        </DialogActions>
                    </Dialog>
                </div>
                <Divider />

                {!project.blocked && 
                    <div>
                        <div className="project">
                            <label className={classes.label}>Zipcode</label>
                            {project.zipcode}
                        </div>
                            <Divider />
                        <div className="project">
                            <label className={classes.label}>Budget</label>
                            $ {project.budgetMin} ~ $ {project.budgetMax}
                        </div>
                            <Divider />
                        <div className="project">
                            <label className={classes.label}>Supporting info</label>
                            {project.supportingInfo}      
                        </div>
                        <Divider />
                        <div className="project">
                            <div className={classes.checkbox} >
                                <label className={classes.label}>Emergency</label>
                                    {project.emergency &&
                                    <CheckedIconSvg fontSize="small"/>}
                                    {!project.emergency && <UncheckedIconSvg fontSize="small"/>}
                            </div>
                            <div className={classes.checkbox} >
                                <label className={classes.label}>Company</label>
                                    {project.company && <CheckedIconSvg fontSize="small"/>}
                                    {!project.company && <UncheckedIconSvg fontSize="small"/>}
                            </div>
                            <div className={classes.checkbox} >
                                <label className={classes.label}>Insurance</label>
                                    {project.insurance && <CheckedIconSvg fontSize="small"/>}
                                    {!project.insurance && <UncheckedIconSvg fontSize="small"/>}
                            </div>
                            <div className={classes.checkbox} >
                                <label className={classes.label}>License</label>
                                    {project.license && <CheckedIconSvg fontSize="small"/>}
                                    {!project.license && <UncheckedIconSvg fontSize="small"/>}
                            </div>
                        </div>
                        <Divider />
                        <div className="project">
                            <label className={classes.label}>Start date:</label> {project.startDate}
                        </div>
                        <div className="project">
                            <label className={classes.label}>Start time:</label> {project.startTime} / 
                            <label className={classes.label}> End time:</label> {project.endTime}
                        </div>
                        <Divider />
                        <div className="project"> 
                            <label className={classes.label}>Proposal selection date:</label> 
                            {project.endBiddingDate}</div>
                        <Divider />
                        <div className="project"><label className={classes.label}>Work date :</label> 
                            {project.day.map(day => (
                                <Button 
                                    disabled
                                    variant={day.value ? 'contained' : 'outlined'} 
                                    size="small" 
                                    color="default" 
                                    className={classes.margin}>
                                    {day.day}
                                </Button>
                            ))}
                        </div>
                    </div>
                    }
            </Paper>
    );
};

export default withStyles(styles)(ProjectDetail);

