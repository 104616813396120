import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';

const styles = theme => ({
    root: {
        fontFamily: "'Noto Sans', sans-serif",
        width: '100%',
        overflowX: 'auto',
        padding: '0px 20px 10px 20px',
    },
    table: {
        width: "100%",
        minWidth: 700,
    },
    th: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    heavy: {
        fontWeight: 600,
    }, 
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    scope: {
        backgroundColor:'#F2F2F2',
        fontSize: '12px',
        
    },
    subLabel: {    
        fontWeight: 700,
        paddingRight: '6px',
    },
    sm: {
        fontSize: '11px',
        padding: '2px',
    },
    rating: {
        fontSize: '20px',
    },
    recom: {
        borderRadius: '20px',
        background: '#73AD21',
        padding: '0px',
        marginLeft: '5px'
    },
    green: {
        color: '#01DF01',
    }, 
});

const ReviewList = (props) => {
    const {
        classes,
        reviews,
        handleClickMessageDialogOpen,
    } = props;

    const showReviews = reviews.length > 0 ? true: false;
    if(!showReviews) {
        return '';
    }
    return (
        <div className={classes.root}>
            {/* <label className={clsx(classes.heavy)}>Reviews</label> */}
                <div>
                    {reviews.map(review => (
                        <div className="block">
                            <div align="left">{review.displayName} <span align="left" className={clsx(classes.sm)} >{review.date}</span>
                            <span className={clsx(classes.sm, classes.recom)}>{review.recommend === "1" ? 'Recommend!' : ''}</span>
                            </div>
                            <div align="left">Project ID: {review.projectId}
                            </div>
                            <Box component="fieldset" borderColor="transparent" >
                                <Rating 
                                    className={clsx(classes.rating, classes.green)}
                                    name="overall"
                                    value={review.overall}
                                    readOnly
                                />
                                <span className={classes.rating}>{review.overall}</span>
                            </Box>
                            
                            <div align="left">{review.comment}
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
        </div>
    );
};

export default withStyles(styles)(ReviewList);

