import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    fontFamily: "'Noto Sans', sans-serif",
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: "100%",
    minWidth: 700,
  },
  head: {
      backgroundColor: '#c2c2c2',
  },
  th: {
      paddingLeft: '3px',
      paddingRight: '2px',
  },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});

const ReviewList = (props) => {
    const {
        classes,
        messageType,
        reivews,
        trs,
    } = props;


    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead className={classes.head}>
                <TableRow>
                    {trs.map(tr => (
                        <TableCell className={classes.th} align={tr.align}>{tr.title}</TableCell>
                    ))}
                </TableRow>
                </TableHead>
                <TableBody>
                    {reivews.map(row => (
                        <TableRow className={classes.row} key={row.id}>
                            {messageType !== 'user-action' &&
                                <TableCell className={classes.th} align="center">{row.displayName}</TableCell>}
                            <TableCell className={classes.th} align="center">{row.projectId}</TableCell>
                            <TableCell className={classes.th} align="center">{row.overall}</TableCell>
                            <TableCell className={classes.th} align="center">{row.recommend === "1" ? 'YES' : 'NO'}</TableCell>
                            <TableCell className={classes.th} align="center">{row.comment}</TableCell>
                            <TableCell className={classes.th} align="center">{row.date}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
        </Paper>
    );
};

export default withStyles(styles)(ReviewList);

